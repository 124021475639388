import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingModal from "../../../../Components/LoadingModal/LoadingModal";
import OrderConfirmationModal from "../../../../Components/OrderConfirmationModal/OrderConfirmationModal";
import { useNavigate } from "react-router-dom";
import {
  setcolor,
  setIcon,
  setmessage,
  setsize,
  settoaststate,
  setvariant,
} from "../../../../features/toastSlice";
import { clearcart } from "../../../../features/cart/cartSlice";
import styles from "./FullDiscountComp.module.css";

const lambdaURL = process.env.REACT_APP_LAMBDA_URL;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const digitalbookendpoint = process.env.REACT_APP_DIGITAL_BOOK_ENDPOINT

const FullDiscountComp = ({
  fields,
  totalCount,
  details,
  cart,
  setErrors,
  tax,
  country,
  finalTotalPrice,
  totalBasePrice,
  setDetails,
  discount
}) => {
  const {
    cart: { isPromoapplied, voucherPromoCode },
  } = useSelector((cart) => cart);
  // console.log("isPromoapplied", isPromoapplied);
  // console.log("voucherPromoCode", voucherPromoCode);

  const {
    gender,
    book,
    eyeColor,
    skincolor,
    hairstyle,
    haircolor,
    town,
    childname,
  } = useSelector((state) => state.characterinfo);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [progressbar, setProgressbar] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });
  const steps = [
    "Payment",
    "Creating Your Book",
    "Order Completed",
    "Finalizing",
  ];

  const handleClose = () => setOpen(false);

  const [openOrderModal, setOpenOrderModal] = useState(false);

  const handleCloseOrderModal = () => {
    setOpenOrderModal(false);
    navigate("/");
  };

  const dispatch = useDispatch();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const generateRandom6DigitNumber = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  const validateFields = () => {
    const errors = {};
    let valid = true;

    if (!fields.firstName) {
      errors.firstName = "First Name cannot be empty";
      valid = false;
    }
    if (!fields.lastName) {
      errors.lastName = "Last Name cannot be empty";
      valid = false;
    }
    if (!fields.AddressLine1) {
      errors.AddressLine1 = "Address Line 1 cannot be empty";
      valid = false;
    }

    if (!fields.City) {
      errors.City = "County/Town cannot be empty";
      valid = false;
    }
    if (!fields.ZipCode) {
      errors.ZipCode = "Postcode cannot be empty";
      valid = false;
    }
    if (!fields.PhoneNumber) {
      errors.PhoneNumber = "Phone Number cannot be empty";
      valid = false;
    }
    if (!fields.Email || !/\S+@\S+\.\S+/.test(fields.Email)) {
      errors.Email = "Invalid email address";
      valid = false;
    }
    setErrors(errors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonLoader(true);

    if (!validateFields()) {
      setButtonLoader(false);
      return;
    }

    if (totalCount < 1) {
      showToast("You do not have any item in cart", "warning");
      return;
    }

    const missingFields = validateAPIFields(fields);

    if (missingFields.length > 0) {
      showToast("Validation failed: Missing or empty fields:", "warning");
      console.error(
        "Validation failed: Missing or empty fields:",
        missingFields
      );
      setButtonLoader(false);
      return;
    }
    setProgressbar((prev) => ({ ...prev, step1: true }));
    setOpen(true);

    if (isPromoapplied) {
      // console.log("Redeeming voucher...");
      const res = await redeemVoucher();
      if (!res.success) {
        setOpen(false);
        setButtonLoader(false);
        showToast("Voucher Code Invalid or Expire", "danger");
        return; // Stop execution here
      }
    } else {
      console.log("Promo is not applied, skipping voucher redemption.");
    }

    try {
      await handlePaymentSuccess();
    } catch (err) {
      console.error("Payment error:", err);
      // setPaymentError('An error occurred. Please try again.');
      setOpen(false);
      setButtonLoader(false);
      showToast("Voucher Code Invalid or Expire", "danger");
    } finally {
      setButtonLoader(false);
    }
  };

  const validateAPIFields = (fields) => {
    const requiredFields = [
      "firstName",
      "lastName",
      "AddressLine1",
      "City",
      "Email",
      "PhoneNumber",
      "ZipCode",
    ];

    const missingFields = requiredFields.filter(
      (field) => !fields[field] || fields[field].trim() === ""
    );

    return missingFields;
  };

  const redeemVoucher = async () => {
    try {
      const res = await fetch(`${apiBaseUrl}/items/redeem-voucher`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          PK: voucherPromoCode,
        }),
      });
      // console.log("res", res);
      const data = await res.json();
      // console.log("data", data);
      return data;
    } catch (err) {
      console.error("error", err);
    }
  };

  const handlePaymentSuccess = async () => {
    let data;
    let isDigital = false;
    setOpen(true);
    setProgressbar((prev) => ({ ...prev, step2: true }));
    const formdata = createFormData();
    try {
      const response = await fetch(`${apiBaseUrl}/items/add-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });

      if (!response.ok) {
        throw new Error("Failed to place order");
      }

      const result = await response.json();
      data = result
      if (result.success === "Order added successfully") {
        const transformedCart = await transformCart(cart, result?.data?.PK);

        // Prepare the new transformedCart for the different formats
        const hardBooks = transformedCart['book-details'].filter(book => book.format === 'hard');
        const softBooks = transformedCart['book-details'].filter(book => book.format === 'soft');
        const bothBooks = transformedCart['book-details'].filter(book => book.format === 'both');

        
            // Conditionally handle the different formats
            if (hardBooks.length > 0) {
              // Handle hard format books
              const hardBooksCart = { ...transformedCart, 'book-details': hardBooks };
              await handleBookPrinting(hardBooksCart, result);
              // console.log("hardBooksCart",hardBooksCart)
          }

          if (bothBooks.length > 0) {
              // Handle both format books
              const bothBooksCart = { ...transformedCart, 'book-details': bothBooks };
              await handleBookPrinting(bothBooksCart, result);
              await handledigitalbooks(bothBooksCart, result);
              isDigital = true
              // console.log("bothBooksCart",bothBooksCart)
          }

          if (softBooks.length > 0) {
              // Handle soft format books
              const softBooksCart = { ...transformedCart, 'book-details': softBooks };
              await handledigitalbooks(softBooksCart, result);
              isDigital = true
              // console.log("softBooksCart",softBooksCart)
          }

        // await handleBookPrinting(transformedCart, result);

        setDetails({ orderDetails: result.data, paymentDetails: null });
      }
    } catch (error) {
      console.error("Order error:", error);
      showToast(
        "Something went wrong creating Book! Please try again",
        "danger"
      );
      setOpen(false);
      setButtonLoader(false);
    } finally{
      localStorage.removeItem('cart');
      await dispatch(clearcart());
      setOpen(false)
      setProgressbar(prev => ({ ...prev, step4: true }))
      setOpenOrderModal(true)
      navigate('/order-confirm', { state: { orderDetails: data.data, isDigital } })
  }
  };

  const showToast = (message, color) => {
    dispatch(settoaststate(true));
    dispatch(setsize("sm"));
    dispatch(setvariant("soft"));
    dispatch(setcolor(color));
    dispatch(setmessage(message));
    dispatch(setIcon(color));
  };

  async function transformCart(cart, orderId) {
    // Extract book details from the cart
    const bookDetails = cart.map((item) => item.variation);

    // Construct the transformed cart object
    const transformedCart = {
      "order-details": {
        "order-id": orderId,
      },
      "book-details": bookDetails,
    };

    return transformedCart;
  }

  const createFormData = () => {
    const randomid = generateRandom6DigitNumber();
    const formattedDate = formatDate(new Date());

    const customerName = localStorage.getItem("CurrentFullName") || "guest";
    const customerID =
      localStorage.getItem("bookstorecurrentloginuser") ||
      `guestUser-${Date.now().toString(36)}`;

    const actualTotalAmount = Number(finalTotalPrice).toFixed(2);

    const shippingDetail = {
      firstName: fields.firstName || "N/A",
      lastName: fields.lastName || "missing",
      AddressLine1: fields.AddressLine1 || "N/A",
      CountyTown: fields.City || "N/A",
      AptSuiteUnit: fields.aptunit || "",
      City: fields.City || "N/A",
      Email: fields.Email || "N/A",
      PhoneNumber: fields.PhoneNumber || "N/A",
      Postcode: fields.ZipCode || "N/A",
      isoCountry: "GB",
    };

    return {
      PK: `orderId-${randomid}`,
      SK: "order",
      orderDate: formattedDate,
      customer: { name: customerName, id: customerID },
      item: cart.length > 0 ? cart : "missing",
      paymentDetails: {
        itemPrice: totalBasePrice || "missing",
        tax: tax || 0,
        totalAmount: actualTotalAmount || "missing",
        paymentID: `payment-${randomid}` || "missing",
        status: "succeeded" || "missing",
        orderCountry: country || "missing",
      },
      shippingDetail,
      amount: actualTotalAmount,
      voucherPromoCode: voucherPromoCode || null,
    };
  };

  const handleBookPrinting = async (transformedCart, result) => {
    setProgressbar((prev) => ({ ...prev, step3: true }));

    try {
      const response = await fetch(lambdaURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transformedCart),
      });

      if (!response.ok) {
        throw new Error("Failed to send book printing data");
      }

      // console.log('Book printing data sent successfully');
      // localStorage.removeItem("cart");
      // await dispatch(clearcart());
      // setOpen(false);
      // navigate("/order-confirm", { state: { orderDetails: result.data } });
      // setProgressbar((prev) => ({ ...prev, step4: true }));
      // setOpenOrderModal(true);
    } catch (error) {
      console.error("Book printing error:", error);
      showToast("Something went wrong! try again", "danger");
      setOpen(false);
    }
  };

  const handledigitalbooks = async (transformedCart,result) => {
    setProgressbar(prev => ({ ...prev, step3: true }))

    try {
        const response = await fetch(digitalbookendpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(transformedCart)
        });

        if (!response.ok) {
            throw new Error('Failed to send book printing data');
        }

        // localStorage.removeItem('cart');
        // await dispatch(clearcart());
        // setOpen(false)
        // // navigate('/order-confirm', { state: { orderDetails: result.data } })
        // setProgressbar(prev => ({ ...prev, step4: true }))
        // setOpenOrderModal(true)

    } catch (error) {
        console.error('digital book generation error:', error);
        showToast('Something went wrong! try again', 'danger');
        setOpen(false)
    } 
};
  return (
    <div>
      <form className={styles["form-container"]} onSubmit={handleSubmit}>
        <h2 className={styles["form-title"]}>Details</h2>
        <div className={styles["fields-container"]}>
          <div className={styles["field-row"]}>
            <span className={styles["field-label"]}>First Name:</span>
            <span className={styles["field-value"]}>
              {fields.firstName || "N/A"}
            </span>
          </div>
          <div className={styles["field-row"]}>
            <span className={styles["field-label"]}>Last Name:</span>
            <span className={styles["field-value"]}>
              {fields.lastName || "N/A"}
            </span>
          </div>
          <div className={styles["field-row"]}>
            <span className={styles["field-label"]}>Address Line 1:</span>
            <span className={styles["field-value"]}>
              {fields.AddressLine1 || "N/A"}
            </span>
          </div>
          <div className={styles["field-row"]}>
            <span className={styles["field-label"]}>Address Line 2:</span>
            <span className={styles["field-value"]}>
              {fields.aptunit || "N/A"}
            </span>
          </div>
          <div className={styles["field-row"]}>
            <span className={styles["field-label"]}>County/Town:</span>
            <span className={styles["field-value"]}>
              {fields.City || "N/A"}
            </span>
          </div>

          <div className={styles["field-row"]}>
            <span className={styles["field-label"]}>Postcode:</span>
            <span className={styles["field-value"]}>
              {fields.ZipCode || "N/A"}
            </span>
          </div>
          <div className={styles["field-row"]}>
            <span className={styles["field-label"]}>Email:</span>
            <span className={styles["field-value"]}>
              {fields.Email || "N/A"}
            </span>
          </div>
          <div className={styles["field-row"]}>
            <span className={styles["field-label"]}>Phone Number:</span>
            <span className={styles["field-value"]}>
              {fields.PhoneNumber || "N/A"}
            </span>
          </div>
        </div>
        <button type="submit" className={styles.styledButton}>
          {buttonLoader ? <div className={styles.loader}></div> : "Order Now"}
        </button>
      </form>
      <LoadingModal
        text="Please wait..."
        open={open}
        handleClose={handleClose}
        progressbar={progressbar}
        steps={steps}
      />
      <OrderConfirmationModal
        open={openOrderModal}
        handleClose={handleCloseOrderModal}
      />
    </div>
  );
};

export default FullDiscountComp;
