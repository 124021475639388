import "./App.css";
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./Pages/ContactUs/ContactUs";
import ShippingAddress from "./Pages/Order/ShippingAddress/ShippingAddress";
import CreateYourCharacter from "./Pages/CreateYourCharacter/CreateYourCharacter";
import Bookcategories from "./Pages/Bookcategories/Bookcategories";
import SigninSignup from "./Pages/SigninSignup/SigninSignup";
import Profile from "./Pages/Profile/Profile";
import OurBooksMain from "./Pages/OurBooksMain/OurBooksMain";
import { useEffect, useRef, useState } from "react";
import OurStory from "./Pages/OurStory/OurStory";
import Cart from "./Pages/Cart/Cart";
import ComingSoon from "./Pages/ComingSoon/ComingSoon";

import { Hub } from "aws-amplify/utils";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

import { useSelector } from "react-redux";
import "@fontsource/inter";
import { MySnackbar } from "./Components/MySnackbar/MySnackbar";
import { useUser } from "./context/UserContext";
import AdminLogin from "./Admin/AdminLogin";
import AdminDashboard from "./Admin/AdminDashboard";
import { useAdmin } from "./context/AdminContext";
import ComingSoonChristmas from "./Pages/ComingSoonChristmas/ComingSoonChristmas";
import VoucherBanner from "./Components/VoucherBanner/VoucherBanner";
import OrderConfirmPage from "./Pages/OrderConfirmPage/OrderConfirmPage";

function App() {
  const location = useLocation();

  const { uniqueId, toaststate, size, variant, color, message, iCon } =
    useSelector((state) => state.toastSliceInfo);

  // const [user, setUser] = useState(
  //   localStorage.getItem("bookstorecurrentloginuser") || null
  // );
  const { fullName, user, setUser } = useUser();

  const containerRefscroll = useRef(null);

  useEffect(() => {
    if (containerRefscroll.current) {
      containerRefscroll.current.scrollTop = 0;
    }
  }, [location.pathname]);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const authUser = await getCurrentUser();
        const userData = await fetchAuthSession();

        const payloaddata = userData?.tokens?.idToken?.payload;
        const groups = payloaddata["cognito:groups"] || [];
        if (groups.includes("Admin")) {
          setUser(null);
        } else {
          setUser(authUser.userId);
        }
      } catch (error) {
        setUser(null);
      }
    };

    checkUser();

    // Subscribe to authentication events to automatically update user state
    Hub.listen("auth", ({ payload }) => {
      if (payload.event === "signIn") {
        checkUser();
      }
      if (payload.event === "signOut") {
        setUser(null);
      }
    });
  }, []);

  const { admindetails } = useAdmin();

  const shouldShowNavbar =
    location.pathname === "/dashboard/admin/login" ||
    location.pathname === "/admin-dashboard" ||
    location.pathname === "/order-confirm";

  return (
    <div className="App">
      {!shouldShowNavbar && <VoucherBanner />}
      {!shouldShowNavbar && (
        <div className="navbarmain">
          <Navbar fullName={fullName} />
        </div>
      )}
      <div
        className={`${
          !shouldShowNavbar ? "mainContentDiv" : "mainContentDivforadmin"
        }`}
        ref={containerRefscroll}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/shippingaddress" element={<ShippingAddress />} />
          <Route
            path="/createyourcharacter"
            element={<CreateYourCharacter />}
          />
          <Route
            path="/our-books/category/:category"
            element={<Bookcategories />}
          />

          {!user && !admindetails && (
            <Route path="/login" element={<SigninSignup />} />
          )}
          {user && <Route path="/login" element={<Navigate to="/" />} />}
          {user && (
            <Route path="/profile" element={<Profile fullName={fullName} />} />
          )}
          {!user && <Route path="/profile" element={<Navigate to="/" />} />}
          <Route path="/our-books" element={<OurBooksMain />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/coming-soon" element={<ComingSoon />} />

          <Route path="/coming-soon/Santa" element={<ComingSoonChristmas />} />

          <Route path="/order-confirm" element={<OrderConfirmPage />} />

          {!user && !admindetails && (
            <Route path="/dashboard/admin/login" element={<AdminLogin />} />
          )}

          {admindetails && !user && (
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
          )}
        </Routes>

        <MySnackbar
          key={uniqueId} // Ensure unique ID
          variant={variant}
          color={color}
          toaststate={toaststate}
          message={message}
          size={size}
          iCon={iCon}
        />
      </div>
    </div>
  );
}

export default App;
