import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import styles from "./customAlert.module.css";
import { useEffect } from "react";

export default function CustomAlert({
  alert,
  setAlert,
  severity,
  vertical = "bottom",
  horizontal = "left",
}) {
  useEffect(() => {
    setTimeout(() => {
      setAlert({
        open: false,
        message: "",
      });
    }, 3000);
  }, [setAlert]);

  let bgColor = "";
  if (severity === "success") {
    bgColor = "green";
  } else if (severity === "error") {
    bgColor = "red";
  }

  return (
    <div className={styles.container}>
      <div className={styles.content} style={{ backgroundColor: bgColor }}>
        <IoMdCheckmarkCircleOutline className={styles.icon} />
        <p>{alert.message}</p>
        <IoMdClose className={styles.icon} />
      </div>
    </div>
  );
}
