import React from "react";
import CreateYourCharacterstyles from "../CreateYourCharacter.module.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const CharacterDetailsBox = (props) => {
  const {
    currectWidth,
    setActiveOption,
    activeOption,
    charactertoggle,
    boyskinOptions,
    boyselectedSkin,
    handleboyskin,

    girlskinOptions,
    selectedSkin,
    setSelectedSkin,

    isglassesselected,
    setIsglassesselected,

    boyeyeOptions,
    boyselectedEyes,
    setBoyselectedEyes,

    girleyeOptions,
    selectedEyes,
    setSelectedEyes,

    selectedHairType,

    handleHairTypeChange,

    handlePrevious,
    currentHairtitle,
    handleNext,

    hairOptions,
    boyselectedHair,
    setBoyselectedHair,
    girlhairnewOptions,
    selectedHair,
    setSelectedHair,
  } = props;
  return (
    <div className={CreateYourCharacterstyles.characterOptionLeft}>
      {currectWidth && (
        <button
          onClick={() => setActiveOption("skin")}
          className={
            activeOption === "skin"
              ? CreateYourCharacterstyles.optionsbtn
              : CreateYourCharacterstyles.toggleButton
          }
        >
          Skin
        </button>
      )}

      <div
        style={{
          display: currectWidth
            ? activeOption === "skin"
              ? "block"
              : "none"
            : "",
          width: "inherit",
        }}
      >
        <div className={CreateYourCharacterstyles.skin}>
          {charactertoggle === "Boy" && (
            <div className={CreateYourCharacterstyles.optionsdivinnercontainer}>
              <h3
                className={CreateYourCharacterstyles.optionheading}
                style={{ paddingLeft: "15px" }}
              >
                Skin:
              </h3>
              <div className={CreateYourCharacterstyles.characteroptionskin}>
                {boyskinOptions.map((option, index) => (
                  <div key={`${option.id}${index}`}>
                    <div
                      style={{
                        border:
                          option.id === boyselectedSkin.id
                            ? "2px solid #452565"
                            : "",
                      }}
                      className={`${CreateYourCharacterstyles.commonskin} ${
                        CreateYourCharacterstyles[option.alt]
                      }`}
                      onClick={() => handleboyskin(option)}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {charactertoggle === "Girl" && (
            <div className={CreateYourCharacterstyles.optionsdivinnercontainer}>
              <h3
                className={CreateYourCharacterstyles.optionheading}
                style={{ paddingLeft: "15px" }}
              >
                Skin:
              </h3>
              <div className={CreateYourCharacterstyles.characteroptionskin}>
                {girlskinOptions.map((option, index) => (
                  <div key={`${option.id}${index}`}>
                    <div
                      style={{
                        border:
                          option.id === selectedSkin.id
                            ? "2px solid #452565"
                            : "",
                      }}
                      className={`${CreateYourCharacterstyles.commonskin} ${
                        CreateYourCharacterstyles[option.alt]
                      }`}
                      onClick={() => setSelectedSkin(option)}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {currectWidth && (
        <button
          onClick={() => setActiveOption("eye")}
          className={
            activeOption === "eye"
              ? CreateYourCharacterstyles.optionsbtn
              : CreateYourCharacterstyles.toggleButton
          }
        >
          eyes
        </button>
      )}

      <div
        style={{
          display: currectWidth
            ? activeOption === "eye"
              ? "block"
              : "none"
            : "",
          width: "inherit",
        }}
      >
        <div className={CreateYourCharacterstyles.eye}>
          {charactertoggle === "Boy" && (
            <div className={CreateYourCharacterstyles.optionsdivinnercontainer}>
              <div className={CreateYourCharacterstyles.inthesameline}>
                <h3 className={CreateYourCharacterstyles.optionheading}>
                  Eyes:
                </h3>
                <div
                  className={`${CreateYourCharacterstyles.togglebuttonglass} ${
                    isglassesselected
                      ? CreateYourCharacterstyles.active
                      : CreateYourCharacterstyles.notactive
                  }`}
                  onClick={() => setIsglassesselected(!isglassesselected)}
                >
                  {isglassesselected ? "Glasses Enabled" : "Glasses Disabled"}
                </div>
              </div>
              <div className={CreateYourCharacterstyles.characteroptionskin}>
                {boyeyeOptions.map((option, index) => (
                  <div key={`${option.id}${index}`}>
                    <div
                      key={option.id}
                      style={{
                        border:
                          option.id === boyselectedEyes.id
                            ? "2px solid #452565"
                            : "",
                      }}
                      className={`${CreateYourCharacterstyles.commoneyehair} ${
                        CreateYourCharacterstyles[option.alt]
                      }`}
                      onClick={() => setBoyselectedEyes(option)}
                    >
                      <img
                        src={option.src}
                        alt={option.alt}
                        className={CreateYourCharacterstyles.theimageeye}
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {charactertoggle === "Girl" && (
            <div className={CreateYourCharacterstyles.optionsdivinnercontainer}>
              <div className={CreateYourCharacterstyles.inthesameline}>
                <h3 className={CreateYourCharacterstyles.optionheading}>
                  Eyes:
                </h3>

                <div
                  className={`${CreateYourCharacterstyles.togglebuttonglass} ${
                    isglassesselected
                      ? CreateYourCharacterstyles.active
                      : CreateYourCharacterstyles.notactive
                  }`}
                  onClick={() => setIsglassesselected(!isglassesselected)}
                >
                  {isglassesselected ? "Glasses Enabled" : "Glasses Disabled"}
                </div>
              </div>
              <div className={CreateYourCharacterstyles.characteroptionskin}>
                {girleyeOptions.map((option, index) => (
                  <div key={`${option.id}${index}`}>
                    <div
                      key={option.id}
                      style={{
                        border:
                          option.id === selectedEyes.id
                            ? "2px solid #452565"
                            : "",
                      }}
                      className={`${CreateYourCharacterstyles.commoneyehair} ${
                        CreateYourCharacterstyles[option.alt]
                      }`}
                      onClick={() => setSelectedEyes(option)}
                    >
                      <img
                        src={option.src}
                        alt={option.alt}
                        className={CreateYourCharacterstyles.theimageeyegirl}
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {currectWidth && (
        <button
          onClick={() => setActiveOption("hair")}
          className={
            activeOption === "hair"
              ? CreateYourCharacterstyles.optionsbtn
              : CreateYourCharacterstyles.toggleButton
          }
        >
          hair
        </button>
      )}

      <div
        style={{
          display: currectWidth
            ? activeOption === "hair"
              ? "block"
              : "none"
            : "",
          width: "inherit",
        }}
      >
        <div className={CreateYourCharacterstyles.hair}>
          <h3 className={CreateYourCharacterstyles.optionheading}>Hair:</h3>
          <div
            style={{
              display: currectWidth
                ? activeOption === "skin"
                  ? "block"
                  : "none"
                : "",
              width: "inherit",
            }}
          >
            <div className={CreateYourCharacterstyles.longshort}>
              <div
                style={{
                  background: selectedHairType === "short" ? "#452565" : "",
                  color: selectedHairType === "short" ? "#fff" : "",
                }}
                className={CreateYourCharacterstyles.short}
                onClick={() => handleHairTypeChange("short")}
              >
                Short
              </div>
              <div
                style={{
                  background: selectedHairType === "long" ? "#452565" : "",
                  color: selectedHairType === "long" ? "#fff" : "",
                }}
                className={CreateYourCharacterstyles.long}
                onClick={() => handleHairTypeChange("long")}
              >
                Long
              </div>
              <div
                style={{
                  background: selectedHairType === "afro" ? "#452565" : "",
                  color: selectedHairType === "afro" ? "#fff" : "",
                }}
                className={CreateYourCharacterstyles.long}
                onClick={() => handleHairTypeChange("afro")}
              >
                Afro
              </div>
              <div
                style={{
                  background: selectedHairType === "curly" ? "#452565" : "",
                  color: selectedHairType === "curly" ? "#fff" : "",
                }}
                className={CreateYourCharacterstyles.long}
                onClick={() => handleHairTypeChange("curly")}
              >
                Curly
              </div>
            </div>
          </div>

          {currectWidth && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={handlePrevious}
                  className={CreateYourCharacterstyles.shadowbtncls}
                >
                  <IoIosArrowBack size={24} />
                </button>
                <div style={{ margin: "0 20px", textAlign: "center" }}>
                  <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {currentHairtitle}
                  </p>
                </div>
                <button
                  onClick={handleNext}
                  className={CreateYourCharacterstyles.shadowbtncls}
                >
                  <IoIosArrowForward size={24} />
                </button>
              </div>
            </div>
          )}

          <div>
            {charactertoggle === "Boy" && (
              <div
                className={CreateYourCharacterstyles.optionsdivinnercontainer}
              >
                <div className={CreateYourCharacterstyles.characteroptionskin}>
                  {hairOptions.map((option, index) => (
                    <div key={`${option.id}${index}`}>
                      <div
                        key={option.id}
                        style={{
                          border:
                            option.id === boyselectedHair.id
                              ? "2px solid #452565"
                              : "",
                        }}
                        className={`${
                          CreateYourCharacterstyles.commoneyehair
                        } ${CreateYourCharacterstyles[option.alt]}`}
                        onClick={() => setBoyselectedHair(option)}
                      >
                        <img
                          src={option.src}
                          alt={option.alt}
                          className={CreateYourCharacterstyles.theimagehair}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {charactertoggle === "Girl" && (
              <div
                className={CreateYourCharacterstyles.optionsdivinnercontainer}
              >
                <div className={CreateYourCharacterstyles.characteroptionskin}>
                  {girlhairnewOptions.map((option, index) => (
                    <div key={`${option.id}${index}`}>
                      <div
                        key={option.id}
                        style={{
                          border:
                            option.id === selectedHair.id
                              ? "2px solid #452565"
                              : "",
                        }}
                        className={`${
                          CreateYourCharacterstyles.commoneyehair
                        } ${CreateYourCharacterstyles[option.alt]}`}
                        onClick={() => setSelectedHair(option)}
                      >
                        <img
                          src={option.src}
                          alt={option.alt}
                          className={CreateYourCharacterstyles.theimagehair}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharacterDetailsBox;
