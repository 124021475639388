// import { createSlice } from "@reduxjs/toolkit";

// function getNumericPrice(price) {
//     if (typeof price === "string") {
//         let numericPrice = price.replace(/[^0-9.]/g, '');
//         return parseFloat(numericPrice) || 0; // Fallback to 0 if parsing fails
//     }
//     return Number(price) || 0; // Fallback to 0 if value is not a number
// }

// // function calculateTax(totalCount) {
// //     if (totalCount === 1) return 3.99;
// //     if (totalCount === 2) return 4.49;
// //     if (totalCount === 3) return 5.49;
// //     if (totalCount >= 4 && totalCount <= 6) return 6.99;
// //     if (totalCount >= 7 && totalCount <= 10) return 8.99;
// //     if (totalCount > 10) return 0; // Direct to contact us for bulk purchases
// //     return 0;
// // }
// function calculateTax(totalCount, country = 'United Kingdom') {
//     if (country === 'Ireland') {
//         if (totalCount === 1) return 5.95;
//         if (totalCount === 2 || totalCount === 3) return 6.95;
//         if (totalCount >= 4 && totalCount <= 6) return 8.49;
//         if (totalCount >= 7 && totalCount <= 10) return 9.95;
//         if (totalCount > 10) return 0; // Direct to contact us for bulk purchases
//     } else { // Default to United Kingdom
//         if (totalCount === 1) return 3.99;
//         if (totalCount === 2) return 4.49;
//         if (totalCount === 3) return 5.49;
//         if (totalCount >= 4 && totalCount <= 6) return 6.99;
//         if (totalCount >= 7 && totalCount <= 10) return 8.99;
//         if (totalCount > 10) return 0; // Direct to contact us for bulk purchases
//     }
//     return 0;
// }

// // Load cart from localStorage
// const loadCartFromLocalStorage = () => {
//     const cartData = localStorage.getItem('cart');
//     if (cartData) {
//         return JSON.parse(cartData);
//     }
//     return {
//         cart: [],
//         totalBasePrice: 0,
//         totalPrice: 0,
//         finalTotalPrice: 0,
//         totalCount: 0,
//         tax: 0,
//         discount: 0,
//         voucherPromoCode: '',
//         isPromoapplied: false,
//         discountType: '',
//         country: 'United Kingdom',
//     };
// };

// const calculateNewPrice = (totalPriceofCart, discountPercentage) => {
//     const discountAmount = totalPriceofCart * (discountPercentage / 100);
//     const newPrice = totalPriceofCart - discountAmount;
//     return newPrice  // Returns the new price with two decimal points
// }

// // Save cart to localStorage
// const saveCartToLocalStorage = (state) => {
//     localStorage.setItem('cart', JSON.stringify(state));
// };

// const initialState = loadCartFromLocalStorage();

// export const cartSlice = createSlice({
//     name: "cart",
//     initialState,
//     reducers: {
//         addToCart: (state, action) => {
//             const existingItemIndex = state.cart.findIndex(cartItem => cartItem.bookTempID === action.payload.bookTempID);
//             if (existingItemIndex !== -1) {
//                 // Item already in cart, update quantity
//                 state.cart[existingItemIndex].quantity += 1;
//             } else {
//                 // New item, add to cart
//                 state.cart.push({ ...action.payload, quantity: 1 });
//             }
//             state.totalCount += 1;
//             const newBasePrice = Number(state.totalBasePrice) + getNumericPrice(action.payload.price);
//             console.log("newBasePrice",newBasePrice)
//             state.totalBasePrice = newBasePrice

//             state.finalTotalPrice = newBasePrice

//             // const ActualDiscount = Number(state.discount.toFixed(2));
//             const discountValue = Number(state.discount);  // Convert to number if it's not already
//             const ActualDiscount = isNaN(discountValue) ? 0 : discountValue
            
//             if(state.isPromoapplied){

//                 if(state.discountType === 'OnlyItems'){
//                 const newPrice = calculateNewPrice(state.totalBasePrice, ActualDiscount);
//                 // console.log("newPricediscount",newPrice)
//                 // const afterdiscount =  ActualDiscount/state.totalBasePrice
//                 state.totalPrice = Number(newPrice)
//                 state.tax = calculateTax(state.totalCount, state.country);
//                 state.finalTotalPrice = Number(newPrice) + calculateTax(state.totalCount,state.country);

//                 saveCartToLocalStorage(state);
//                 }
//                 if(state.discountType === 'Total'){
//                     const newPrice = calculateNewPrice((state.totalBasePrice + calculateTax(state.totalCount, state.country)), ActualDiscount);
//                     // console.log("newPricediscount",newPrice)
//                     // const afterdiscount =  ActualDiscount/state.totalBasePrice
//                     state.totalPrice = Number(newPrice)
//                     state.tax = calculateTax(state.totalCount, state.country);
//                     state.finalTotalPrice = Number(newPrice);
    
//                     saveCartToLocalStorage(state);
//                 }

//             }else{
//                 const newPrice = Number(state.totalPrice) + getNumericPrice(action.payload.price);
//                 // console.log("newPrice",newPrice)
//                 state.totalPrice = Number(newPrice)
//                 state.tax = calculateTax(state.totalCount, state.country);

//                 state.finalTotalPrice = Number(newPrice) + calculateTax(state.totalCount,state.country);

//                 saveCartToLocalStorage(state);
//             }
//         },
//         removeFromCart: (state, { payload }) => {
//             const existingItemIndex = state.cart.findIndex(cartItem => cartItem.bookId === payload.bookId);
//             if (existingItemIndex !== -1) {
//                 const item = state.cart[existingItemIndex];
//                 // console.log("state.cart.length",state.cart.length)
//                 if (item.quantity === 1 && state.cart.length === 1) {
//                     state.discount = 0;
//                     state.isPromoapplied = false;
//                     state.voucherPromoCode = '';
//                 }

//                 item.quantity -= 1;
//                 if (item.quantity <= 0) {
//                     state.cart.splice(existingItemIndex, 1);
//                     // state.isPromoapplied = true;
//                     // console.log("item.quantity",item.quantity)
//                 }
                
//                 state.totalCount -= 1;
//                 const newBasePrice = Number(state.totalBasePrice) - getNumericPrice(payload.price);
//                 state.totalBasePrice = Number(newBasePrice);

//                 if(state.isPromoapplied){
//                     if(state.discountType === 'OnlyItems'){
//                         state.totalPrice = Number((state.totalBasePrice * (1 - state.discount / 100)))
//                         state.finalTotalPrice = Number((state.totalBasePrice * (1 - state.discount / 100))) + calculateTax(state.totalCount, state.country);
//                     }
//                     if(state.discountType === 'Total'){
//                         state.totalPrice = Number((state.totalBasePrice * (1 - state.discount / 100)))
//                         state.finalTotalPrice = Number(((state.totalBasePrice  + calculateTax(state.totalCount,state.country)) * (1 - state.discount / 100)));
//                     }
//                 }else{
//                     state.totalPrice = Number(state.totalBasePrice);
//                     state.finalTotalPrice = Number(state.totalBasePrice) + calculateTax(state.totalCount, state.country);
//                 }

//                 state.tax = calculateTax(state.totalCount,state.country);
//                 saveCartToLocalStorage(state);
//             }
//         },
//         // setCountry: (state, { payload }) => {
//         //     if (payload === 'United Kingdom' || payload === 'Ireland') {
//         //         state.country = payload;
//         //         state.tax = calculateTax(state.totalCount, state.country);
//         //         state.finalTotalPrice = state.totalPrice + state.tax;
//         //         saveCartToLocalStorage(state);
//         //     }
//         // },
//         setCountry: (state, { payload }) => {
//             if (payload === 'United Kingdom' || payload === 'Ireland') {
//                 state.country = payload;
//                 // Recalculate tax based on the new country
//                 state.tax = calculateTax(state.totalCount, state.country);
        
//                 // Recalculate the final total price based on the discount and tax
//                 if (state.isPromoapplied) {
//                     // Recalculate prices considering the discount
//                     if (state.discountType === 'OnlyItems') {
//                         const discountAmount = (state.totalBasePrice * state.discount) / 100;
//                         state.totalPrice = state.totalBasePrice - discountAmount;
//                         state.finalTotalPrice = state.totalPrice + state.tax;
//                     }
//                     if (state.discountType === 'Total') {
//                         const discountAmount = ((state.totalBasePrice + state.tax) * state.discount) / 100;
//                         state.totalPrice = (state.totalBasePrice + state.tax) - discountAmount;
//                         state.finalTotalPrice = state.totalPrice;
//                     }
//                 } else {
//                     state.totalPrice = state.totalBasePrice;
//                     state.finalTotalPrice = state.totalBasePrice + state.tax;
//                 }
        
//                 // Save the updated state to localStorage
//                 saveCartToLocalStorage(state);
//             }
//         },
        
//         applyDiscount: (state, { payload }) => {
//             // Apply discount based on the base price (before any discount)

//             state.discount = payload.discount; // Assume payload.discount is the percentage discount
//             state.discountType = payload.discountType;
    
//             console.log("payload",payload)
//             console.log("state.discountType",state.discountType)
//             // if(state.discountType === "OnlyItems"){
            

//             state.isPromoapplied = true;
//             state.voucherPromoCode = payload.voucherPromoCode;

//             state.tax = calculateTax(state.totalCount,state.country);
//             if(state.discountType === 'OnlyItems'){
//                 const discountAmount = (state.totalBasePrice * payload.discount) / 100;

//                 state.totalPrice = Number((state.totalBasePrice - discountAmount)); // Apply discount
//                 state.finalTotalPrice = state.totalPrice + calculateTax(state.totalCount, state.country)
//             }
           
//             if(state.discountType === 'Total'){
//                 const discountAmount = ((state.totalBasePrice + calculateTax(state.totalCount, state.country)) * payload.discount) / 100;

//                 state.totalPrice = Number(((state.totalBasePrice + calculateTax(state.totalCount, state.country)) - discountAmount)); // Apply discount
//                 console.log("state.totalPrice",state.totalPrice)
//                 state.finalTotalPrice = state.totalPrice
//             }

//             saveCartToLocalStorage(state);
//         },
//         clearVoucher: (state) => {
//             state.isPromoapplied = false;
//             state.totalPrice = Number(state.totalBasePrice);
//             state.finalTotalPrice = Number(state.totalBasePrice) + calculateTax(state.totalCount, state.country);
//             state.voucherPromoCode = '';
//             state.discount = 0;
//             state.discountType = ''
//             saveCartToLocalStorage(state);
//         },
//         clearcart: (state) => {
//             // Clear the cart
//             state.cart = [];
//             state.totalBasePrice = 0;
//             state.finalTotalPrice = 0;
//             state.totalPrice = 0;
//             state.totalCount = 0;
//             state.tax = 0;
//             state.country = 'United Kingdom';
//             state.discount = 0;
//             state.discountType = ''
//             state.isPromoapplied = false;
//             state.voucherPromoCode = '';
//             saveCartToLocalStorage(state);
//         }
//     }
// });

// export const { addToCart, removeFromCart, applyDiscount, clearVoucher, clearcart, setCountry } = cartSlice.actions;
// export default cartSlice.reducer;



import { createSlice } from "@reduxjs/toolkit";

function getNumericPrice(price) {
    if (typeof price === "string") {
        let numericPrice = price.replace(/[^0-9.]/g, '');
        return parseFloat(numericPrice) || 0; // Fallback to 0 if parsing fails
    }
    return Number(price) || 0; // Fallback to 0 if value is not a number
}

// function calculateTax(totalCount) {
//     if (totalCount === 1) return 3.99;
//     if (totalCount === 2) return 4.49;
//     if (totalCount === 3) return 5.49;
//     if (totalCount >= 4 && totalCount <= 6) return 6.99;
//     if (totalCount >= 7 && totalCount <= 10) return 8.99;
//     if (totalCount > 10) return 0; // Direct to contact us for bulk purchases
//     return 0;
// }
function calculateTax(totalCount, country = 'United Kingdom') {
    if (country === 'Ireland') {
        if (totalCount === 1) return 5.95;
        if (totalCount === 2 || totalCount === 3) return 6.95;
        if (totalCount >= 4 && totalCount <= 6) return 8.49;
        if (totalCount >= 7 && totalCount <= 10) return 9.95;
        if (totalCount > 10) return 0; // Direct to contact us for bulk purchases
    } else { // Default to United Kingdom
        if (totalCount === 1) return 3.99;
        if (totalCount === 2) return 4.49;
        if (totalCount === 3) return 5.49;
        if (totalCount >= 4 && totalCount <= 6) return 6.99;
        if (totalCount >= 7 && totalCount <= 10) return 8.99;
        if (totalCount > 10) return 0; // Direct to contact us for bulk purchases
    }
    return 0;
}

// Load cart from localStorage
const loadCartFromLocalStorage = () => {
    const cartData = localStorage.getItem('cart');
    if (cartData) {
        return JSON.parse(cartData);
    }
    return {
        cart: [],
        totalBasePrice: 0,
        totalPrice: 0,
        finalTotalPrice: 0,
        totalCount: 0,
        tax: 0,
        discount: 0,
        voucherPromoCode: '',
        isPromoapplied: false,
        discountType: '',
        country: 'United Kingdom',
    };
};

const calculateNewPrice = (totalPriceofCart, discountPercentage) => {
    const discountAmount = totalPriceofCart * (discountPercentage / 100);
    const newPrice = totalPriceofCart - discountAmount;
    return newPrice  // Returns the new price with two decimal points
}

// Save cart to localStorage
const saveCartToLocalStorage = (state) => {
    localStorage.setItem('cart', JSON.stringify(state));
};

const initialState = loadCartFromLocalStorage();

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        // addToCart: (state, action) => {
        //     console.log("state",state)
        //     console.log("action",action)
        //     const existingItemIndex = state.cart.findIndex(cartItem => cartItem.bookTempID === action.payload.bookTempID);
        //     if (existingItemIndex !== -1) {
        //         // Item already in cart, update quantity
        //         state.cart[existingItemIndex].quantity += 1;
        //     } else {
        //         // New item, add to cart
        //         state.cart.push({ ...action.payload, quantity: 1 });
        //     }
        //     state.totalCount += 1;
        //     const newBasePrice = Number(state.totalBasePrice) + getNumericPrice(action.payload.price);
        //     console.log("newBasePrice",newBasePrice)
        //     state.totalBasePrice = newBasePrice

        //     state.finalTotalPrice = newBasePrice

        //     // const ActualDiscount = Number(state.discount.toFixed(2));
        //     const discountValue = Number(state.discount);  // Convert to number if it's not already
        //     const ActualDiscount = isNaN(discountValue) ? 0 : discountValue
            
        //     if(state.isPromoapplied){

        //         if(state.discountType === 'OnlyItems'){
        //         const newPrice = calculateNewPrice(state.totalBasePrice, ActualDiscount);
        //         // console.log("newPricediscount",newPrice)
        //         // const afterdiscount =  ActualDiscount/state.totalBasePrice
        //         state.totalPrice = Number(newPrice)
        //         state.tax = calculateTax(state.totalCount, state.country);
        //         state.finalTotalPrice = Number(newPrice) + calculateTax(state.totalCount,state.country);

        //         saveCartToLocalStorage(state);
        //         }
        //         if(state.discountType === 'Total'){
        //             const newPrice = calculateNewPrice((state.totalBasePrice + calculateTax(state.totalCount, state.country)), ActualDiscount);
        //             // console.log("newPricediscount",newPrice)
        //             // const afterdiscount =  ActualDiscount/state.totalBasePrice
        //             state.totalPrice = Number(newPrice)
        //             state.tax = calculateTax(state.totalCount, state.country);
        //             state.finalTotalPrice = Number(newPrice);
    
        //             saveCartToLocalStorage(state);
        //         }

        //     }else{
        //         const newPrice = Number(state.totalPrice) + getNumericPrice(action.payload.price);
        //         // console.log("newPrice",newPrice)
        //         state.totalPrice = Number(newPrice)
        //         state.tax = calculateTax(state.totalCount, state.country);

        //         state.finalTotalPrice = Number(newPrice) + calculateTax(state.totalCount,state.country);

        //         saveCartToLocalStorage(state);
        //     }
        // },
        
        addToCart(state, action) {
            const { price, variation: { format }, bookTempID } = action.payload;
            const existingItemIndex = state.cart.findIndex(item => item.bookTempID === bookTempID);
        
            let basePrice = getNumericPrice(price);
            let additionalCharge = 0;
        
            // Check if the new item has a format of "hard" or "both"
            const isHardOrBoth = format === "hard" || format === "both";
        
            if (format === "soft") {
                basePrice = 7.95;
            } else if (isHardOrBoth) {
                additionalCharge = format === "both" ? 5.65 : 0;
            }
        
            if (existingItemIndex !== -1) {
                state.cart[existingItemIndex].quantity += 1;
            } else {
                state.cart.push({ ...action.payload, quantity: 1 });
            }
        
            state.totalCount += 1;
            state.totalBasePrice += basePrice + additionalCharge;
        
            // Calculate tax only for items with "hard" or "both" formats
            const hardOrBothCount = state.cart.reduce((count, item) => {
                if (item.variation?.format === "hard" || item.variation?.format === "both") {
                    return count + item.quantity;
                }
                return count;
            }, 0);
        
            state.tax = calculateTax(hardOrBothCount, state.country);
        
            if (state.isPromoapplied) {
                state.totalPrice = calculateNewPrice(state.totalBasePrice, state.discount);
                state.finalTotalPrice = state.totalPrice + state.tax;
            } else {
                state.totalPrice = state.totalBasePrice;
                state.finalTotalPrice = state.totalBasePrice + state.tax;
            }
        
            saveCartToLocalStorage(state);
        },
        
        removeFromCart(state, { payload }) {
            const existingItemIndex = state.cart.findIndex(cartItem => cartItem.bookId === payload.bookId);
        
            if (existingItemIndex !== -1) {
                const item = state.cart[existingItemIndex];
                const { variation: { format } } = item;
        
                let priceToSubtract = getNumericPrice(payload.price);
                let additionalCharge = format === "both" ? 5.65 : 0;
        
                if (format === "soft") {
                    priceToSubtract = 7.95;
                }
        
                item.quantity -= 1;
                if (item.quantity <= 0) {
                    state.cart.splice(existingItemIndex, 1);
                }
        
                state.totalCount -= 1;
                state.totalBasePrice = Math.max(0, state.totalBasePrice - (priceToSubtract + additionalCharge));
        
                // Recalculate tax only for items with "hard" or "both" formats
                const hardOrBothCount = state.cart.reduce((count, item) => {
                    if (item.variation?.format === "hard" || item.variation?.format === "both") {
                        return count + item.quantity;
                    }
                    return count;
                }, 0);
        
                state.tax = calculateTax(hardOrBothCount, state.country);
        
                state.totalPrice = state.isPromoapplied
                    ? calculateNewPrice(state.totalBasePrice, state.discount)
                    : state.totalBasePrice;
        
                state.finalTotalPrice = state.totalPrice + state.tax;
        
                if (state.cart.length === 0) {
                    state.discount = 0;
                    state.isPromoapplied = false;
                    state.voucherPromoCode = '';
                    state.discountType = '';
                }
        
                saveCartToLocalStorage(state);
            }
        },
        
        
        
        // setCountry: (state, { payload }) => {
        //     if (payload === 'United Kingdom' || payload === 'Ireland') {
        //         state.country = payload;
        //         state.tax = calculateTax(state.totalCount, state.country);
        //         state.finalTotalPrice = state.totalPrice + state.tax;
        //         saveCartToLocalStorage(state);
        //     }
        // },
        setCountry: (state, { payload }) => {
            if (payload === 'United Kingdom' || payload === 'Ireland') {
                state.country = payload;
        
                // Recalculate tax only for items with "hard" or "both" formats
                const hardOrBothCount = state.cart.reduce((count, item) => {
                    if (item.variation?.format === "hard" || item.variation?.format === "both") {
                        return count + item.quantity;
                    }
                    return count;
                }, 0);
        
                state.tax = calculateTax(hardOrBothCount, state.country);
        
                // Recalculate the final total price based on the discount and tax
                if (state.isPromoapplied) {
                    if (state.discountType === 'OnlyItems') {
                        const discountAmount = (state.totalBasePrice * state.discount) / 100;
                        state.totalPrice = state.totalBasePrice - discountAmount;
                        state.finalTotalPrice = state.totalPrice + state.tax;
                    }
                    if (state.discountType === 'Total') {
                        const discountAmount = ((state.totalBasePrice + state.tax) * state.discount) / 100;
                        state.totalPrice = (state.totalBasePrice + state.tax) - discountAmount;
                        state.finalTotalPrice = state.totalPrice;
                    }
                } else {
                    state.totalPrice = state.totalBasePrice;
                    state.finalTotalPrice = state.totalBasePrice + state.tax;
                }
        
                saveCartToLocalStorage(state);
            }
        },
        
        applyDiscount: (state, { payload }) => {
            state.discount = payload.discount;
            state.discountType = payload.discountType;
            state.isPromoapplied = true;
            state.voucherPromoCode = payload.voucherPromoCode;
        
            // Recalculate tax only for items with "hard" or "both" formats
            const hardOrBothCount = state.cart.reduce((count, item) => {
                if (item.variation?.format === "hard" || item.variation?.format === "both") {
                    return count + item.quantity;
                }
                return count;
            }, 0);
        
            state.tax = calculateTax(hardOrBothCount, state.country);
        
            if (state.discountType === 'OnlyItems') {
                const discountAmount = (state.totalBasePrice * payload.discount) / 100;
                state.totalPrice = Number((state.totalBasePrice - discountAmount));
                state.finalTotalPrice = state.totalPrice + state.tax;
            }
        
            if (state.discountType === 'Total') {
                const discountAmount = ((state.totalBasePrice + state.tax) * payload.discount) / 100;
                state.totalPrice = Number(((state.totalBasePrice + state.tax) - discountAmount));
                state.finalTotalPrice = state.totalPrice;
            }
        
            saveCartToLocalStorage(state);
        },

        clearVoucher: (state) => {
    state.isPromoapplied = false;
    state.voucherPromoCode = '';
    state.discount = 0;
    state.discountType = '';

    // Recalculate tax only for items with "hard" or "both" formats
    const hardOrBothCount = state.cart.reduce((count, item) => {
        if (item.variation?.format === "hard" || item.variation?.format === "both") {
            return count + item.quantity;
        }
        return count;
    }, 0);

    state.tax = calculateTax(hardOrBothCount, state.country);

    // Recalculate the final total price without the discount
    state.totalPrice = Number(state.totalBasePrice);
    state.finalTotalPrice = state.totalPrice + state.tax;

    saveCartToLocalStorage(state);
},
        clearcart: (state) => {
            // Clear the cart
            state.cart = [];
            state.totalBasePrice = 0;
            state.finalTotalPrice = 0;
            state.totalPrice = 0;
            state.totalCount = 0;
            state.tax = 0;
            state.country = 'United Kingdom';
            state.discount = 0;
            state.discountType = ''
            state.isPromoapplied = false;
            state.voucherPromoCode = '';
            saveCartToLocalStorage(state);
        }
    }
});

export const { addToCart, removeFromCart, applyDiscount, clearVoucher, clearcart, setCountry } = cartSlice.actions;
export default cartSlice.reducer;
