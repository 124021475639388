import React, { useState, useEffect } from "react";
import VoucherList from "./VoucherList";
import VoucherForm from "./VoucherForm";
import styles from "./AdminDashboard.module.css";
import { signOut } from "aws-amplify/auth";
import { clearcart } from "../features/cart/cartSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UpdateVoucher from "./UpdateVoucher";
import { Oval } from "react-loader-spinner";
import MessagePopup from "./Messagepopup";
import AllCustomerOrders from "./AllCustomerOrders";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const AdminDashboard = () => {
  const [vouchers, setVouchers] = useState([]);
  const [selectedVoucher, setSelectedVoucher] = useState(null);

  const [popupData, setPopupData] = useState({
    message: "",
    status: "",
    show: false,
  });

  const [loading, setLoading] = useState(false);

  const [islogoutpopupopen, setIslogoutpopupopen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);

  const [activeoption, setActiveOption] = useState("All-Vouchers");
  // Fetch all vouchers on component mount
  useEffect(() => {
    setLoading(true);
    fetchVouchers();
  }, []);

  const fetchVouchers = async () => {
    try {
      const res = await fetch(`${apiBaseUrl}/items/admin-get-all-vouchers`);

      const data = await res.json();
      setVouchers(data);

      await fetchOrders();
    } catch (error) {
      setLoading(false);
      console.error("Error fetching vouchers:", error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/items/get-orders`);
      if (!response.ok) {
        throw new Error("Failed to fetch orders");
      }
      const data = await response.json();
      setOrders(data);
    } catch (err) {
      setPopupData({ message: err.message, status: "error", show: true });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectVoucher = (voucher) => {
    setSelectedVoucher(voucher);
  };

  const handleVoucherAdded = async () => {
    await fetchVouchers();
    setSelectedVoucher(null);
  };

  const handleToggleOptions = (option) => {
    if (option === "All-Vouchers") {
      setSelectedVoucher(null);
    }

    if (activeoption === option) {
      return;
    }
    setActiveOption(option);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      localStorage.removeItem("bookstorecurrentusername");
      localStorage.removeItem("bookstorecurrentloginuser");
      localStorage.removeItem("bookstoreadminloginusername777");
      localStorage.removeItem("CurrentFullName");
      await dispatch(clearcart());
      localStorage.removeItem("orderData");
      localStorage.removeItem("cart");

      navigate("/iwanttobea/admin-login");
      window.location.reload();
    } catch (error) {
      console.error("Error signing out:", error);
    } finally {
      // setLoading(false);
      // setShowOptions(false);
      // setShowMobileMenu(false);
    }
  };

  // Function to manually close the popup
  const handlePopupClose = () => {
    setPopupData({ ...popupData, show: false });
  };

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.top}>
        <div className={styles.optionsmain}>
          <div>
            <span
              className={`${styles.btndashboard} ${
                activeoption === "All-Vouchers" ? styles.btndashboardActive : ""
              }`}
              onClick={() => handleToggleOptions("All-Vouchers")}
            >
              All Vouchers
            </span>
            <span
              className={`${styles.btndashboard} ${
                activeoption === "Add-New-Voucher"
                  ? styles.btndashboardActive
                  : ""
              }`}
              onClick={() => handleToggleOptions("Add-New-Voucher")}
            >
              Add New Voucher
            </span>

            <span
              className={`${styles.btndashboard} ${
                activeoption === "Orders" ? styles.btndashboardActive : ""
              }`}
              onClick={() => handleToggleOptions("Orders")}
            >
              Orders
            </span>
          </div>
          <span
            className={styles.btndashboard}
            onClick={() => setIslogoutpopupopen(true)}
          >
            Logout
          </span>
        </div>
      </div>
      <div className={styles.mainContent}>
        {activeoption === "All-Vouchers" && (
          <VoucherList
            setLoading={setLoading}
            setIslogoutpopupopen={setIslogoutpopupopen}
            popupData={popupData}
            setPopupData={setPopupData}
            vouchers={vouchers}
            onSelect={handleSelectVoucher}
            onVoucherAdded={handleVoucherAdded}
          />
        )}
        {activeoption === "Add-New-Voucher" && (
          <VoucherForm
            onVoucherAdded={handleVoucherAdded}
            popupData={popupData}
            setPopupData={setPopupData}
            setActiveOption={setActiveOption}
          />
        )}

        {activeoption === "Orders" && (
          <AllCustomerOrders
            onVoucherAdded={handleVoucherAdded}
            popupData={popupData}
            setPopupData={setPopupData}
            setActiveOption={setActiveOption}
            orders={orders}
            fetchOrders={fetchOrders}
            setLoading={setLoading}
          />
        )}
      </div>

      {islogoutpopupopen && (
        <div className={styles.logoutpopup}>
          <div className={styles.logoutpopupbox}>
            <div
              className={styles.closeicon}
              onClick={() => setIslogoutpopupopen(false)}
            >
              <span>&times;</span>
            </div>
            <span className={styles.text}>Are you sure?</span>
            <p className={styles.textparagraph}>
              Are you sure you want to logout?
            </p>
            <div className={styles.btns}>
              <span
                className={styles.cancel}
                onClick={() => setIslogoutpopupopen(false)}
              >
                Cancel
              </span>
              <span className={styles.logout} onClick={handleLogout}>
                Yes
              </span>
            </div>
          </div>
        </div>
      )}

      {selectedVoucher && (
        <div className={styles.editvoucher}>
          <UpdateVoucher
            popupData={popupData}
            setPopupData={setPopupData}
            onVoucherAdded={handleVoucherAdded}
            selectedVoucher={selectedVoucher}
            setSelectedVoucher={setSelectedVoucher}
          />
        </div>
      )}

      {loading && (
        <div className={styles.loader}>
          <Oval
            visible={true}
            height="80"
            width="80"
            color="#fff"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}

      {/* Conditionally render the popup */}
      {popupData.show && (
        <MessagePopup
          message={popupData.message}
          status={popupData.status}
          onClose={handlePopupClose}
        />
      )}
    </div>
  );
};

export default AdminDashboard;
