import React from "react";
import styles from "./CustomButton.module.css"; // Import the CSS file

const CustomButton = ({
  isloading,
  outerDivBg,
  innerDivBg,
  iconwidth,
  iconHeight,
  onClick,
  text,
  fontSize,
  width,
  height,
  icon,
  incoBg = false,
  topGradient,
  bottomGradient,
}) => {
  return (
    <div style={{ width: width, height: height }}>
      <div
        className={styles.contactusbtn}
        onClick={onClick}
        style={{
          width: width,
          height: height,
          background: `linear-gradient(to bottom, ${topGradient}, ${bottomGradient})`,
        }}
      >
        <div
          className={styles.contactusbtninside}
          style={{
            background: `linear-gradient(to bottom,${bottomGradient}, ${topGradient})`,
          }}
        >
          <span style={{ fontSize: fontSize }}>{text}</span>
          {icon && (
            <div
              className={`${styles.iconContainer}`}
              style={{
                backgroundColor: incoBg && "rgba(255, 255, 255, 0.31)",
                width: iconwidth,
                height: iconHeight,
                padding: "0.2rem",
              }}
            >
              {icon}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomButton;
