import React from "react";
import AboutusComponentStyle from "./AboutusComponent.module.css";

const AboutusComponent = () => {
  return (
    <div className={AboutusComponentStyle.AboutusComponent}>
      <h1 className={AboutusComponentStyle.heading}>About Us</h1>
      <p className={AboutusComponentStyle.paragraph}>
        At “ I Want To Be “ we take pride in creating magical and personalized
        stories that captivate and inspire young minds. Our specialized approach
        ensures that every book is unique and tailored specifically to your
        child, making them the star of their very own adventure.
      </p>
    </div>
  );
};

export default AboutusComponent;
