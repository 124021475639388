import React, { createContext, useState, useContext, useEffect } from 'react';

// Initialize the context
const OrderContext = createContext();

// Define the provider component
export const OrderProvider = ({ children }) => {

  const [orderData, setOrderData] = useState(() => {
    const savedOrderData = localStorage.getItem('orderData');
    return savedOrderData ? JSON.parse(savedOrderData) : {
      "order-details": {
        "order-id": ""
      },
      "book-details": []
    };
  });

  // Save orderData to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('orderData', JSON.stringify(orderData));
  }, [orderData]);

  // const [orders, setOrders] = useState(() => {
  //   const savedOrders = localStorage.getItem("orders");
  //   return savedOrders ? JSON.parse(savedOrders) : [];
  // });

  const [orders, setOrders] = useState([]);

  const [ebookorders, setEbookorders] = useState([]);

  // useEffect(() => {
  //   localStorage.setItem("orders", JSON.stringify(orders));
  // }, [orders]);

  const fetchOrders = async (customerId) => {
    if (!customerId || orders.length > 0) return; // Avoid refetching

    const url = `${process.env.REACT_APP_API_BASE_URL}/items/get-orders-for-customer?customerId=${encodeURIComponent(customerId)}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();

      // const extractedData = data.flatMap(order =>
      //   order.item.map(item => ({
      //     orderDate: order.orderDate,
      //     totalPrice: order.totalPrice,
      //     rating: item.rating,
      //     quantity: item.quantity,
      //     title: item.title,
      //     price: item.price,
      //     bookId: item.bookId,
      //     imageUrl: item.imageUrl,
      //   }))
      // );
      // setOrders(extractedData);

      const extractedData = data.flatMap(order =>
        order.item
          .filter(item => Array.isArray(item.e_book_url) && item.e_book_url.length > 0) // Ensure valid e_book_url
          .map(item => ({
            orderDate: order.orderDate,
            totalPrice: order.totalPrice,
            PK: order.PK,
            customerID: order.customerID,
            ...item, // Spread the complete item object
          }))
      );
      setEbookorders(extractedData);

      setOrders(data);
    } catch (err) {
      console.error("Error fetching customer orders:", err);
    }
  };

  const addBookDetail = (newBookDetail) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": [...prevState["book-details"], newBookDetail]
    }));
  };

  const resetOrderData = () => {
    const initialOrderData = {
      "order-details": {
        "order-id": ""
      },
      "book-details": []
    };
    setOrderData(initialOrderData);
  };

  const updateOrderId = (newOrderId) => {
    setOrderData(prevState => ({
      ...prevState,
      "order-details": {
        ...prevState["order-details"],
        "order-id": newOrderId
      }
    }));
  };

  const removeBookDetailById = (tempBookID) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": prevState["book-details"].filter(book => book["bookTempID"] !== tempBookID)
    }));
  };

  const updateBookQuantityById = (tempBookID, newQuantity) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": prevState["book-details"].map(book =>
        book["bookTempID"] === tempBookID
          ? { ...book, quantity: newQuantity }
          : book
      )
    }));
  };

  const incrementBookQuantityById = (tempBookID) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": prevState["book-details"].map(book =>
        book["bookTempID"] === tempBookID
          ? { ...book, quantity: book.quantity + 1 }
          : book
      )
    }));
  };

  const decrementBookQuantityById = (tempBookID) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": prevState["book-details"].map(book => {
        if (book["bookTempID"] === tempBookID) {
          const newQuantity = book.quantity - 1;
          return newQuantity > 0 ? { ...book, quantity: newQuantity } : null;
        }
        return book;
      }).filter(book => book !== null)
    }));
  };

  return (
    <OrderContext.Provider value={{ fetchOrders,ebookorders, orders, setOrders, orderData, incrementBookQuantityById,setOrderData, addBookDetail, updateOrderId, removeBookDetailById, updateBookQuantityById,decrementBookQuantityById,resetOrderData }}>
      {children}
    </OrderContext.Provider>
  );
};

// Custom hook for using the context
export const useOrder = () => useContext(OrderContext);
