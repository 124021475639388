import React, { createContext, useState, useContext } from 'react';

// Create a context with default values
const UserContext = createContext({
  fullName: '',
  setFullName: () => {}
});

// Provider component
export const UserProvider = ({ children }) => {
  // const [userDetailsglobal, setUserDetailsglobal] = useState(null);
const [userDetails, setUserDetails] = useState({
    email: "",
    lastName: "",
    firstName: "",
  });
    const [fullName, setFullName] = useState(() => {
        const storedFullName = localStorage.getItem("CurrentFullName");
        return storedFullName || null; // Default to empty string if not found
      });
    
      const [user, setUser] = useState(
        localStorage.getItem("bookstorecurrentloginuser") || null
      );

  return (
    <UserContext.Provider value={{ fullName, setFullName, userDetails, setUserDetails, user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);
