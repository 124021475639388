import { useEffect, useState } from "react";
import ProfileTopStyles from "./profileTop.module.css";
import OrderHistory from "../OrderHistory/OrderHistory";
import AccountDetails from "../AccountDetails/AccountDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { Backdrop, CircularProgress } from "@mui/material";
import EbooksDetails from "../EbooksDetails/EbooksDetails";

export default function ProfileTop({ fullName }) {
  const location = useLocation();
  const { thenav } = location.state || {}; // Safely access state

  const [nav, setNav] = useState(thenav || 1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getFirstName = (fullName) => {
    if (!fullName) return ""; // Handle the case where fullName is null or undefined
    const names = fullName.split(" ");
    return names[0]; // Return the first name
  };

  const handleLogout = async () => {
    try {
      setLoading(true); // Show the backdrop
      await signOut();
      localStorage.removeItem("bookstorecurrentusername");
      localStorage.removeItem("bookstorecurrentloginuser");
      localStorage.removeItem("CurrentFullName");
      navigate("/");
      window.location.reload();
    } catch (error) {
      setLoading(false); // Show the backdrop
      console.log("Error signing out:", error);
    }
  };

  return (
    <>
      <div className={ProfileTopStyles.main}>
        <h1 className={ProfileTopStyles.mainheading}>
          Welcome to your wonderful world {getFirstName(fullName)}
        </h1>

        <div className={ProfileTopStyles.bookContainer}>
          {/* left side */}
          <div className={ProfileTopStyles.profileTopLeft}>
            {/* <h3 className={ProfileTopStyles.leftTitle}>
              {getFirstName(fullName)}
            </h3> */}
            <div className={ProfileTopStyles.leftBtnsContainer}>
              <button
                style={{
                  background: nav === 1 && "#BACD53",
                  color: nav === 1 && "#fff",
                }}
                className={ProfileTopStyles.leftBtns}
                onClick={() => setNav(1)}
              >
                Home
              </button>
            </div>
            <div className={ProfileTopStyles.leftBtnsContainer}>
              <button
                style={{
                  background: nav === 2 && "#BACD53",
                  color: nav === 2 && "#fff",
                }}
                className={ProfileTopStyles.leftBtns}
                onClick={() => setNav(2)}
              >
                Account Details
              </button>
            </div>
            <div className={ProfileTopStyles.leftBtnsContainer}>
              <button
                style={{
                  background: nav === 4 && "#BACD53",
                  color: nav === 4 && "#fff",
                }}
                className={ProfileTopStyles.leftBtns}
                onClick={() => setNav(4)}
              >
                Ebooks
              </button>
            </div>
            <div className={ProfileTopStyles.leftBtnsContainer}>
              <button
                style={{
                  background: nav === 3 && "#BACD53",
                  color: nav === 3 && "#fff",
                }}
                className={ProfileTopStyles.leftBtns}
                onClick={handleLogout}
              >
                log out
              </button>
            </div>
          </div>
          {/* right side */}
          {/* <div className={ProfileTopStyles.profileTopRight}> */}
          {/* order history */}
          {nav === 1 && <OrderHistory />}
          {nav === 2 && <AccountDetails />}
          {nav === 4 && <EbooksDetails />}
          {/* </div> */}
        </div>
      </div>
      <Backdrop open={loading} style={{ zIndex: 1300 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
