import OurBooksStyles from './OurBooks.module.css'
import BookCard from '../BookCard/BookCard';

const apiforimagesBaseUrl = process.env.REACT_APP_IMAGES_CLOUDFRONT_URL;

const booksData = [
  { bookId: 'Santa', title: "I Want To Be Santa's Helper", rating: 5, category: 'Santa', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/santacover.webp` },
  { bookId: 'Astronaut', title: 'I Want To Be An Astronaut', rating: 5, category: 'Astronaut', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/Book1.webp` },
  { bookId: 'Doctor', title: 'I Want To Be A Doctor', rating: 5, category: 'Doctor', price: 14.95, digital: 7.95, both:20.60, imageUrl:  `${apiforimagesBaseUrl}/BookCovers/docterboy.webp` },
  { bookId: 'Firefighter', title: 'I Want To Be A Firefighter',rating: 5, category: 'Firefighter', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/firefightergirl_cover.webp` },
  { bookId: 'Footballer', title: 'I Want To Be A Footballer', rating: 5, category: 'Footballer', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/footballergirl.webp` },
  { bookId: 'Youtuber', title: 'I Want To Be A Youtuber', rating: 5, category: 'Youtuber', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/youtubegirl.webp` },
  { bookId: 'Nurse', title: 'I Want To Be A Nurse', rating: 5, category: 'Nurse', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/nursegirl.webp` },
  { bookId: 'Teacher', title: 'I Want To Be A Teacher', rating: 5, category: 'Teacher', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/teacherboy.webp` },
  { bookId: 'TrainDriver', title: 'I Want To Be A Train Driver', rating: 5, category: 'TrainDriver', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/drivergirl.webp` },
  { bookId: 'PoliceOfficer', title: 'I Want To Be A Police Officer', rating: 5, category: 'PoliceOfficer', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/policeboy.webp` },
  { bookId: 'VetDoctor', title: 'I Want To Be A Vet', rating: 5, category: 'VetDoctor', price: 14.95, digital: 7.95, both:20.60, imageUrl: `${apiforimagesBaseUrl}/BookCovers/vetboycover.webp` },
];

const OurBooks = ({titleColor}) => {
  return (
    <>
      {booksData &&
        <div className={OurBooksStyles.main}>
          <h1 style={{color: titleColor}} className={OurBooksStyles.mainheading}>Our Books</h1>

          <div className={OurBooksStyles.bookContainer}>
            {booksData.map((book) => (
              <div key={book.bookId}>
              <BookCard
                product={book}
              />
              </div>
            ))}
          </div>
        </div>
      }
    </>
  );
};

export default OurBooks;
