import React, { useState } from "react";
import ContactUsstyle from "./ContactUs.module.css";
import "../../fonts.css";
import Footer from "../../Components/Footer/Footer";
import BookLoader from "../../Components/BookLoader/BookLoader";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { useDispatch } from "react-redux";
import {
  setcolor,
  setIcon,
  setmessage,
  setsize,
  setuniqueId,
  settoaststate,
  setvariant,
} from "../../features/toastSlice";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const ContactUs = () => {
  const dispatch = useDispatch();

  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    subject: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    subject: "",
  });

  const handleInputChange = (e) => {
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      subject: "",
    });

    setFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleErrors = (key, value) => {
    setErrors((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const [loading, setloading] = useState(false);

  const handleSubmit = async () => {
    let bol = true;
    if (!fields.firstName) {
      handleErrors("firstName", "First name cannot be empty");
      bol = false;
    } else {
      handleErrors("firstName", "");
      bol = true;
    }

    if (!fields.lastName) {
      handleErrors("lastName", "Last name cannot be empty");
      bol = false;
    } else {
      handleErrors("lastName", "");
      bol = true;
    }

    if (!fields.email) {
      handleErrors("email", "Email cannot be empty");
      bol = false;
    } else {
      handleErrors("email", "");
      bol = true;
    }

    if (!fields.message) {
      handleErrors("message", "Message cannot be empty");
      bol = false;
    } else {
      handleErrors("message", "");
      bol = true;
    }

    if (!fields.subject) {
      handleErrors("subject", "Subject cannot be empty");
      bol = false;
    } else {
      handleErrors("Subject", "");
      bol = true;
    }

    if (!bol) {
      return;
    }
    setloading(true);
    try {
      const response = await fetch(`${apiBaseUrl}/items/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: fields.firstName,
          lastname: fields.lastName,
          email: fields.email,
          subject: fields.subject,
          message: fields.message,
        }),
      });

      if (response.status === 200) {
        setFields({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          subject: "",
        });

        dispatch(settoaststate(true));
        dispatch(setsize("sm"));
        dispatch(setvariant("soft"));
        dispatch(setcolor("success"));
        dispatch(setmessage("Email sent succesfully"));
        dispatch(setIcon("success"));
        dispatch(setuniqueId(`contactus`));
      }

      setloading(false);
    } catch (error) {
      console.log("error", error);
      setloading(false);
    }
    setloading(false);
  };

  return (
    <>
      {loading && <BookLoader />}

      {!loading && (
        <div className={ContactUsstyle.ContactUs}>
          <div className={ContactUsstyle.mainContainer}>
            <h1 className={ContactUsstyle.heading}>Contact Us</h1>
            <div className={ContactUsstyle.content}>
              <p className={ContactUsstyle.paragraph}>
                We’re thrilled to have you drop by our website. Would LOVE to
                hear from you.
                <br />
                Whether it’s an idea for a new book, some feedback on our
                website,
                <br />
                or simply just to say “hi” then use the form below or email us
                at:{" "}
                <a
                  href="mailto:hello@iwant2bea.com"
                  className={ContactUsstyle.email}
                >
                  hello@iwant2bea.com
                </a>
                <br />
              </p>
            </div>
            <div className={ContactUsstyle.main}>
              <div className={ContactUsstyle.form}>
                <div className={ContactUsstyle.row}>
                  <div className={ContactUsstyle.field}>
                    <label htmlFor="firstName">First Name</label>
                    <input
                      onChange={handleInputChange}
                      type="text"
                      value={fields.firstName}
                      id="firstName"
                      name="firstName"
                    />
                    {errors.firstName && (
                      <p className={ContactUsstyle.error}>{errors.firstName}</p>
                    )}
                  </div>
                  <div className={ContactUsstyle.field}>
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      onChange={handleInputChange}
                      type="text"
                      value={fields.lastName}
                      id="lastName"
                      name="lastName"
                    />
                    {errors.lastName && (
                      <p className={ContactUsstyle.error}>{errors.lastName}</p>
                    )}
                  </div>
                </div>
                <div className={ContactUsstyle.row}>
                  <div className={ContactUsstyle.field}>
                    <label htmlFor="email">Email</label>
                    <input
                      onChange={handleInputChange}
                      type="email"
                      value={fields.email}
                      id="email"
                      name="email"
                    />
                    {errors.email && (
                      <p className={ContactUsstyle.error}>{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className={ContactUsstyle.row}>
                  <div className={ContactUsstyle.field}>
                    <label htmlFor="subject">Subject</label>
                    <input
                      onChange={handleInputChange}
                      type="text"
                      value={fields.subject}
                      id="subject"
                      name="subject"
                    />
                    {errors.subject && (
                      <p className={ContactUsstyle.error}>{errors.subject}</p>
                    )}
                  </div>
                </div>
                <div className={ContactUsstyle.row}>
                  <div className={ContactUsstyle.field}>
                    <label htmlFor="message">Message</label>
                    <textarea
                      onChange={handleInputChange}
                      id="message"
                      value={fields.message}
                      name="message"
                    ></textarea>
                    {errors.message && (
                      <p className={ContactUsstyle.error}>{errors.message}</p>
                    )}
                  </div>
                </div>
                <div className={ContactUsstyle.rowbtn}>
                  <CustomButton
                    incoBg={true}
                    icon={
                      <IoIosArrowForward
                        style={{ width: "20px", height: "20px" }}
                      />
                    }
                    outerDivBg={"#BB82F9"}
                    innerDivBg={"#AA76E2"}
                    text={"Send"}
                    fontSize={"18px"}
                    onClick={handleSubmit}
                    width={"150px"}
                    height={"55px"}
                    topGradient={"#F6B4D1"}
                    bottomGradient={"#F8CDE0"}
                  />
                </div>
              </div>
            </div>
            <div className={ContactUsstyle.HomeContactus}>
              <div className={ContactUsstyle.Footer}>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUs;
