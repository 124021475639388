import React, { useState } from "react";
import styles from "./VoucherList.module.css";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const VoucherList = ({
  vouchers,
  onSelect,
  onVoucherAdded,
  popupData,
  setIslogoutpopupopen,
  setPopupData,
  setLoading,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isActiveFilter, setIsActiveFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedVoucher, setSelectedVoucher] = useState(null);

  const [isDeletepopupopen, setIsDeletepopupopen] = useState(false);

  const [vouchersPerPage] = useState(5);

  // Handle search filtering
  const handleSearchChange = (e) => {
    const trimmedSearchTerm = e.target.value.trim();
    setSearchTerm(trimmedSearchTerm);
    setCurrentPage(1);
  };

  // Handle isActive filter
  const handleIsActiveFilterChange = (e) => {
    setIsActiveFilter(e.target.value);
  };
  // Filter vouchers based on search term and isActive status
  const filteredVouchers = vouchers.filter((voucher) => {
    // const matchesSearch = voucher.voucherName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || voucher.PK?.toString().toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSearch =
      !searchTerm ||
      (voucher.voucherName &&
        voucher.voucherName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (voucher.PK &&
        voucher.PK.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (voucher.campaign &&
        voucher.campaign.toLowerCase().includes(searchTerm.toLowerCase()));
    const matchesActiveFilter =
      isActiveFilter === "All" ||
      (isActiveFilter === "Active" && voucher.isActive) ||
      (isActiveFilter === "Inactive" && !voucher.isActive);
    return matchesSearch && matchesActiveFilter;
  });
  // vouchers.slice(0, 10).forEach(voucher => console.log(voucher));

  // Handle pagination
  const indexOfLastVoucher = currentPage * vouchersPerPage;
  const indexOfFirstVoucher = indexOfLastVoucher - vouchersPerPage;
  const currentVouchers = filteredVouchers.slice(
    indexOfFirstVoucher,
    indexOfLastVoucher
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredVouchers.length / vouchersPerPage);

  const handleDeleteVoucher = async (voucher) => {
    setIsDeletepopupopen(false);
    setLoading(true);
    try {
      const res = await fetch(`${apiBaseUrl}/items/delete/voucher`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          PK: voucher.PK,
        }),
      });

      // console.log("res", res);

      // Check if there is content in the response
      if (res.status === 204) {
        setLoading(false);
        setPopupData({
          message: "Voucher deleted successfully!",
          status: "success",
          show: true,
        });
        // console.log("Voucher deleted successfully");
        setSearchTerm("");
        setCurrentPage(1);
        onVoucherAdded();

        // Handle success - show popup or refresh the list
      } else {
        const data = await res.json();
        setLoading(false);
        setPopupData({
          message: "Something went wrong!",
          status: "failure",
          show: true,
        });
        // Handle other possible cases based on the response
      }
      setIsDeletepopupopen(false);
    } catch (error) {
      setIslogoutpopupopen(false);
      setLoading(false);
      console.error("Error deleting voucher:", error);
      setPopupData({
        message: "Something went wrong!",
        status: "failure",
        show: true,
      });
      // Handle error - show popup or retry logic
    }
  };

  const handleSelectedVoucher = (voucher) => {
    setIsDeletepopupopen(true);
    setSelectedVoucher(voucher);
    // setLoading(true)
  };

  // Truncated pagination logic
  const getPagination = () => {
    const pageNumbers = [];
    if (totalPages <= 5) {
      // If total pages are less than or equal to 5, show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // If total pages are more than 5, create a truncated pagination
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage > totalPages - 3) {
        pageNumbers.push(
          1,
          "...",
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pageNumbers.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }
    return pageNumbers;
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber === "...") return; // Do nothing when '...' is clicked
    setCurrentPage(pageNumber);
  };

  return (
    <div className={styles.main}>
      {/* Filters */}
      <div className={styles.filterContainer}>
        <span className={styles.heading}>Available Vouchers</span>
        {vouchers.length > 0 && (
          <div className={styles.filter}>
            <input
              type="text"
              placeholder="Search by Voucher Code"
              value={searchTerm}
              onChange={handleSearchChange}
              className={styles.searchInput}
            />
            <select
              value={isActiveFilter}
              onChange={handleIsActiveFilterChange}
              className={styles.selectFilter}
            >
              <option value="All">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
        )}
      </div>

      {/* Voucher Table */}
      <div className={styles.voucherListContainer}>
        {currentVouchers.length > 0 ? (
          <table className={styles.voucherTable}>
            <thead>
              <tr>
                <th>Voucher Code</th>
                <th>Voucher Name</th>
                <th>Campaign</th>
                <th>Discount</th>
                <th>Limit</th>
                <th>Remaining</th>
                <th>Usage</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Active</th>
                <th>Discount Type</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {currentVouchers.map((voucher) => (
                <tr className={styles.listmaindiv} key={voucher.PK}>
                  <td>{voucher.PK}</td>
                  <td>{voucher.voucherName}</td>
                  <td>{voucher.campaign}</td>
                  <td>{voucher.Discount}%</td>
                  <td>{voucher.Limit}</td>
                  <td>{voucher.Remaining}</td>
                  <td>{voucher.Usage}</td>
                  <td>{voucher.StartDate}</td>
                  <td>{voucher.EndDate}</td>
                  <td>{voucher.isActive ? "Yes" : "No"}</td>
                  <td>{voucher.discountType}</td>
                  <td>
                    <button
                      className={styles.editButton}
                      onClick={() => onSelect(voucher)}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      className={styles.deleteButton}
                      onClick={() => handleSelectedVoucher(voucher)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No vouchers found</p>
        )}
      </div>
      {/* Pagination */}
      <div className={styles.pagination}>
        {getPagination().map((pageNumber, i) => (
          <button
            key={i}
            onClick={() => handlePageClick(pageNumber)}
            className={`${styles.pageItem} ${
              currentPage === pageNumber ? styles.activePage : ""
            }`}
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </button>
        ))}
      </div>

      {isDeletepopupopen && (
        <div className={styles.logoutpopup}>
          <div className={styles.logoutpopupbox}>
            <div
              className={styles.closeicon}
              onClick={() => setIsDeletepopupopen(false)}
            >
              <span>&times;</span>
            </div>
            <span className={styles.text}>Are you sure?</span>
            <p className={styles.textparagraph}>
              Are you sure you want to Delete this Voucher?
            </p>
            <div className={styles.btns}>
              <span
                className={styles.cancel}
                onClick={() => setIsDeletepopupopen(false)}
              >
                Cancel
              </span>
              <span
                className={styles.logout}
                onClick={() => handleDeleteVoucher(selectedVoucher)}
              >
                Yes
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VoucherList;
