import React, { useEffect, useRef, useState } from "react";
import EbookReaderstyle from "./EbookReader.module.css"; // Import the necessary styles
import FlipBook from "react-pageflip";

import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

// const importAll = (r) => r.keys().map(r);
// const allimages = importAll(require.context('../../../../../../output-images', false, /\.(png|jpe?g|svg)$/));

const EbookReader = ({ handlepopupclose, itemdata }) => {
  const flipBookRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(2);
  const [imagesLoaded, setImagesLoaded] = useState(false); // Track image loading state
  const [showFlipBook, setShowFlipBook] = useState(false); // To control visibility of flipbook

  // Preload images with a delay after they are loaded
  useEffect(() => {
    if (itemdata?.e_book_url) {
      const loadImages = async () => {
        const promises = itemdata?.e_book_url.map((src) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
          });
        });

        // Wait for all images to load
        await Promise.all(promises);

        // Add a delay before setting imagesLoaded to true
        setTimeout(() => {
          setImagesLoaded(true); // Set loaded after images are done loading
          setShowFlipBook(true); // Now show the flipbook after a delay
        }, 1000); // Add a delay of 1 second (1000ms)
      };
      loadImages();
    }
  }, [itemdata]);

  const handleNext = () => {
    if (flipBookRef.current && currentPage < itemdata?.e_book_url.length - 1) {
      flipBookRef.current.pageFlip().flipNext();
    }
  };

  const handlePrev = () => {
    if (flipBookRef.current && currentPage > 0) {
      flipBookRef.current.pageFlip().flipPrev();
    }
  };

  // Sync currentPage with FlipBook's internal state
  const handleFlip = (e) => {
    if (!e || !e.data) return;
    setCurrentPage(e.data);
  };

  // Calculate the progress
  const totalPages = itemdata?.e_book_url.length;
  const progress = (currentPage / (totalPages - 2)) * 100;

  return (
    <div className={EbookReaderstyle["modal-overlay"]}>
      <div className={EbookReaderstyle["modal-content"]}>
        <button
          className={EbookReaderstyle["close-btn"]}
          onClick={handlepopupclose}
        >
          X
        </button>
        {!imagesLoaded ? (
          // <div className={EbookReaderstyle["pdf-book-wrapper"]}>
            <div className={EbookReaderstyle["loading-book"]}>
              <p>Loading book...</p>
            {/* </div> */}
          </div>
        ) : (
          <>
            <div className={EbookReaderstyle["pdf-book-wrapper"]}>
              <h2 className={EbookReaderstyle["heading"]}>{itemdata?.title}</h2>

              <div className={EbookReaderstyle["flipbook-container"]}>
                {itemdata?.e_book_url && itemdata?.e_book_url.length > 0 ? (
                  <>
                    <button
                      className={`${EbookReaderstyle["books-nav-btn"]}`}
                      disabled={currentPage === 0 || currentPage === 2}
                      onClick={handlePrev}
                    >
                      <MdOutlineKeyboardDoubleArrowLeft />
                    </button>

                    <FlipBook
                      ref={flipBookRef}
                      // width={500}
                      // height={500}
                      // size="stretch"
                      // minWidth={240}
                      // maxWidth={650}
                      // minHeight={300}
                      // maxHeight={700}

                      width={500}
                      height={500}
                      size="stretch"
                      minWidth={350}
                      maxWidth={650}
                      minHeight={450}
                      maxHeight={700}

                      startPage={0}
                      flippingTime={800}
                      showCover={false}
                      mobileScrollSupport={true}
                      onFlip={handleFlip}
                      spaceBetweenPages={10}
                      pageShadow="rgba(0, 0, 0, 0.1) 0px 0px 10px"
                      className={EbookReaderstyle["flipbook"]}
                    >
                      {itemdata?.e_book_url &&
                        itemdata?.e_book_url.map((image, index) => (
                          <div
                            key={index}
                            className={`${EbookReaderstyle["flipbook-page"]} ${
                              index === 0
                                ? EbookReaderstyle["flipbook-cover-page"]
                                : EbookReaderstyle["flipbook-normal-page"]
                            }`}
                          >
                            <img src={image} alt={`Page ${index + 1}`} />
                          </div>
                        ))}
                    </FlipBook>

                    <button
                      className={EbookReaderstyle["books-nav-btn"]}
                      onClick={handleNext}
                      disabled={currentPage >= itemdata?.e_book_url.length - 2}
                    >
                      <MdOutlineKeyboardDoubleArrowRight />
                    </button>
                  </>
                ) : (
                  <p>No images to display</p>
                )}
              </div>

              <div className={EbookReaderstyle["mobile-buttons"]}>
              <button
                      className={`${EbookReaderstyle["mobile-books-nav-btn"]}`}
                      disabled={currentPage === 0 || currentPage === 2}
                      onClick={handlePrev}
                    >
                      <MdOutlineKeyboardDoubleArrowLeft />
                    </button>
                    <button
                      className={EbookReaderstyle["mobile-books-nav-btn"]}
                      onClick={handleNext}
                      disabled={currentPage >= itemdata?.e_book_url.length - 2}
                    >
                      <MdOutlineKeyboardDoubleArrowRight />
                    </button>
              </div>

              {/* Progress Bar */}
              <div className={EbookReaderstyle["progress-bar-wrapper"]}>
                <div className={EbookReaderstyle["progress-bar"]}>
                  <div
                    className={EbookReaderstyle["progress"]}
                    style={{ width: `${progress}%` }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EbookReader;
