import React from "react";
import OurBestSellersstyles from "./OurBestSellers.module.css";
import BookCard from "../../../../Components/BookCard/BookCard";

const apiforimagesBaseUrl = process.env.REACT_APP_IMAGES_CLOUDFRONT_URL;

const booksData = [
  {
    bookId: "Astronaut",
    title: "I Want To Be An Astronaut",
    rating: 5,
    category: "Astronaut",
    price: 14.95,
    digital: 7.95, both:20.60,
    imageUrl: `${apiforimagesBaseUrl}/BookCovers/Book1.webp`,
  },
  {
    bookId: "Doctor",
    title: "I Want To Be A Doctor",
    rating: 5,
    price: 14.95,
    digital: 7.95, both:20.60,
    category: "Doctor",
    imageUrl: `${apiforimagesBaseUrl}/BookCovers/docterboy.webp`,
  },
  {
    bookId: "Firefighter",
    title: "I Want To Be A Firefighter",
    rating: 5,
    price: 14.95,
    digital: 7.95, both:20.60,
    category: "Firefighter",
    imageUrl: `${apiforimagesBaseUrl}/BookCovers/firefightergirl_cover.webp`,
  },
  {
    bookId: "Youtuber",
    title: "I Want To Be A Youtuber",
    rating: 5,
    price: 14.95,
    digital: 7.95, both:20.60,
    category: "Youtuber",
    imageUrl: `${apiforimagesBaseUrl}/BookCovers/youtubegirl.webp`,
  },
];

const OurBestSellers = ({ titleColor, subheading }) => {
  return (
    <div className={OurBestSellersstyles.main}>
      <h1
        style={{ color: titleColor }}
        className={OurBestSellersstyles.mainheading}
      >
        Our Bestsellers
      </h1>
      <p
        style={{ color: subheading }}
        className={OurBestSellersstyles.subheading}
      >
        Personalised stories loved by millions
      </p>
      <div className={OurBestSellersstyles.bookContainer}>
        {booksData.map((book) => (
          <div key={book.bookId}>
            <BookCard product={book} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurBestSellers;
