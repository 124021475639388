import Homestyles from './profile.module.css'
import '../../fonts.css';
import Footer from '../../Components/Footer/Footer';
import ProfileTop from './Components/ProfileTop/ProfileTop';

const Profile = ({fullName}) => {

  return (
    <>
      <div className={Homestyles.HomeContainer}>
          <ProfileTop fullName={fullName} />
        <div className={Homestyles.HomeContactus}>
          <div className={Homestyles.Footer}>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile