import BookCardStyle from "./BookCard.module.css";
import { useNavigate } from "react-router-dom";
import Stars from "../../SvgIcons/Stars/Stars";
import santacap from "../../Assets/Images/siteImages/santacap.webp";

const BookCard = ({ product }) => {
  const navigate = useNavigate();
  if (!product) {
    return;
  }
  const addItemtocart = (itemProductuct) => {
    const statecart = { imgURL: product.imageUrl, routeProduct: itemProductuct };

    if (!itemProductuct.category) {
      navigate("/coming-soon", { statecart });
      return;
    }

    if (itemProductuct.category === "") {
      navigate(`/our-books/category/${itemProductuct.category}`, {
        state: { imgURL: product.imageUrl },
      });
      return;
    }

    const uniqueId = `${itemProductuct.bookId}${Math.random()}`;

    navigate("/createyourcharacter", {
      state: { productToRoute: itemProductuct, uniqueIdToRoute: uniqueId },
    });
  };

  const handleNavigate = () => {
    const path = `/our-books/category/${product.category}`;
    const state = { imgURL: product.imageUrl, routeProduct: product };

    if (!product.category) {
      // navigate('/coming-soon');
      navigate("/coming-soon", { state });
      return;
    }

    if (product.category === "") {
      navigate(path, { state });
    } else {
      navigate(path, { state });
    }
  };

  return (
    <div className={BookCardStyle.card} key={product.bookId}>
      <div className={BookCardStyle.imageContainer} onClick={handleNavigate}>
        {product.bookId === "Santa" && (
          <div className={BookCardStyle.santacapcls}>
            <img src={santacap} alt="santacap" loading="lazy" />
          </div>
        )}
        <img
          src={product.imageUrl}
          alt={product.title}
          className={BookCardStyle.image}
          loading="lazy"
        />
      </div>

      <div className={BookCardStyle.details}>
        <span className={BookCardStyle.title} onClick={handleNavigate}>
          {product.title}
        </span>

        <Stars width="80" height="25" />
        <div className={BookCardStyle.priceCart}>
          <span className={BookCardStyle.price}>£{product.price}</span>
          <span
            className={BookCardStyle.cart}
            onClick={() => addItemtocart(product)}
          >
            Add to Cart
          </span>
        </div>
      </div>
    </div>
  );
};

export default BookCard;
