import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from './CustomModal.module.css';

const ConfirmModal = ({ isOpen, onClose, onConfirm }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const optionValues = {
    "Digital Copy": "soft",
    "Hard Copy (Shipped to Your Address)": "hard",
    "Both (Digital & Hard Copy)": "both",
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modal} onClick={onClose}>
      <motion.div
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
      >
        {/* 📚  */}
        <div className={styles.heading}>Choose Your eBook Format</div>
        <div className={styles.modalBody}>
          <p>How would you like to receive your Book? Choose an option below to proceed.</p>

          <div className={styles.options}>
            {["Digital Copy", "Hard Copy (Shipped to Your Address)", "Both (Digital & Hard Copy)"].map((option) => (
              <motion.button
                key={option}
                className={`${styles.optionButton} ${selectedOption === option ? styles.selected : ""}`}
                onClick={() => setSelectedOption(option)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {option}
              </motion.button>
            ))}
          </div>
        </div>
        
        <div className={styles.modalFooter}>
          <button className={styles.cancelButton} onClick={onClose}>
            Cancel
          </button>
          <motion.button
            className={styles.confirmButton}
            // onClick={() => selectedOption && onConfirm(selectedOption)}
            onClick={() => selectedOption && onConfirm(optionValues[selectedOption])}
            disabled={!selectedOption}
            whileHover={!selectedOption ? {} : { scale: 1.05 }}
            whileTap={!selectedOption ? {} : { scale: 0.95 }}
          >
            Confirm Selection
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default ConfirmModal;
