import React, { useEffect, useState } from "react";
import ProfileTopStyles from "./EbooksDetails.module.css";
import { useOrder } from "../../../../context/OrderContext";
import starIcon from "../../../../Assets/SVG/startSvg.svg";
import filledStar from "../../../../Assets/SVG/starFilled.svg";
import EbookReader from "./Components/EbookReader";

const stars = [starIcon, starIcon, starIcon, starIcon, starIcon];

const EbooksDetails = () => {
  const [customerId, setCustomerId] = useState(
    localStorage.getItem("bookstorecurrentloginuser") || null
  );
  const { fetchOrders, setOrders, ebookorders } = useOrder();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showpopup, setShowpopup] = useState(false);
  const [itemdata, setItemdata] = useState(null);

  const handleFetchOrders = async () => {
    if (!customerId) {
      setError("Please provide a customer ID.");
      return;
    }
    setLoading(true);

    try {
      await fetchOrders(customerId);
      setError(null);
    } catch (err) {
      console.error("Error fetching customer orders:", err);
      setError("Unable to fetch orders. Please try again later.");
      setOrders([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      handleFetchOrders(customerId);
    }
  }, [customerId]);

  function formatOrderDate(orderDate) {
    const date = new Date(orderDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  function extractImageUrl(urlString) {
    if (urlString) {
      const regex = /url\((['"]?)(.*?)\1\)/;
      const match = urlString.match(regex);
      return match ? match[2] : urlString;
    }
    return null;
  }

  function formatPrice(item) {
    if(item.variation.format === 'soft'){ 
      const quantity = parseFloat(item.quantity);
      // const price = parseFloat(item.price);
      const totalPrice = quantity * 7.95;

      return quantity > 1 ? `£${totalPrice.toFixed(2)}` : `£7.95`;
    }
    else if(item.variation.format === 'both'){ 
      const quantity = parseFloat(item.quantity);
      // const price = parseFloat(item.price);
      const totalPrice = quantity * 20.60;

      return quantity > 1 ? `£${totalPrice.toFixed(2)}` : `£20.60`;
    }
    else{ 
      const quantity = parseFloat(item.quantity);
      const price = parseFloat(item.price);
      const totalPrice = quantity * price;

      return quantity > 1 ? `£${totalPrice.toFixed(2)}` : `£${price.toFixed(2)}`;
    }
  }

  const handleopenBookpopup = (book) => {
    setShowpopup(true);
    setItemdata(book);
  };

  const handlepopupclose = () => {
    setShowpopup(false);
    setItemdata(null);
  };

  return (
    <div className={ProfileTopStyles.profileTopRight}>
      <h1 className={ProfileTopStyles.rightTitle}>eBooks</h1>
      {loading ? (
        <div className={ProfileTopStyles.loaderDiv}>
          <div className={ProfileTopStyles.loader}></div>
        </div>
      ) : ebookorders.length !== 0 ? (
        <div className={ProfileTopStyles.profileRightContent}>
          {ebookorders.map((book, bookindex) => (
              <div key={bookindex} className={ProfileTopStyles.orderItem} onClick={() => handleopenBookpopup(book)}>
                <div className={ProfileTopStyles.imgContainer}>
                  <img
                    src={extractImageUrl(book.imageUrl)}
                    alt=""
                    className={ProfileTopStyles.itemImg}
                  />
                </div>
                <div className={ProfileTopStyles.itemDetailContainer}>
                  <div className={ProfileTopStyles.itemTop}>
                    <div className={ProfileTopStyles.insameline}>
                    <p className={ProfileTopStyles.itemTitle}>{book.title}</p>
                    <div className={ProfileTopStyles.ribbon}>
                                          {book?.variation?.["format"] === "both"
                                            ? "Hard & Digital"
                                            : book?.variation?.["format"] === "soft"
                                            ? "Digital only"
                                            : "Hard Only"}
                                        </div>
                                        </div>
                    <div className={ProfileTopStyles.starsContainer}>
                      {Array.from({ length: book.rating }, (_, index) => (
                        <img
                          key={index}
                          src={filledStar}
                          alt="Star"
                          style={{ color: "yellow" }}
                        />
                      ))}
                      {stars.slice(book.rating, 5).map((star, index) => (
                        <img
                          key={index}
                          src={star}
                          alt="Star"
                          style={{ color: "yellow" }}
                        />
                      ))}
                    </div>
                  </div>
                  <div className={ProfileTopStyles.itemBottom}>
                    <span className={ProfileTopStyles.date}>
                      Order Date: {formatOrderDate(book.orderDate)}
                    </span>
                    <span className={ProfileTopStyles.priceContainer}>
                      {book.quantity}{" "}
                      {parseFloat(book.quantity) > 1 ? "Books" : "Book"}, Price{" "}
                      <span className={ProfileTopStyles.price}>
                        {formatPrice(book)}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
          ))}
        </div>
      ) : (
        <div className={ProfileTopStyles.profileRightContent}>
          <h4 className={ProfileTopStyles.rightSubTitle}>
            You have no ebooks in your orders.
          </h4>
        </div>
      )}
      {showpopup && (
        <EbookReader handlepopupclose={handlepopupclose} itemdata={itemdata} />
      )}
    </div>
  );
};

export default EbooksDetails;
