import { useEffect, useState } from "react";
import styles from "./OTPConfirmationModel.module.css";
import { useNavigate } from "react-router-dom";
import { confirmSignUp, autoSignIn, getCurrentUser, fetchAuthSession, resendSignUpCode } from 'aws-amplify/auth';
import { useUser } from "../../../context/UserContext";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const OTPConfirmationModel = (props) => {
  const { username, setOtpmodel } = props;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [timer, setTimer] = useState(60); // Timer in seconds
  const [error, setError] = useState(""); // Error state
  const { setFullName } = useUser();
  
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);


  const handleAddUserAPI = async (formData) => {
   
    try {
      const response = await fetch(`${apiBaseUrl}/items/add-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Something went wrong');
      }

      const responseData = await response.json();
      // Optionally, handle success (e.g., clear form, display message)
    } catch (error) {
      console.error('There was an error adding the user!', error.message);
      // Optionally, handle error (e.g., display error message)
    }
  };


  const handleConfirm = async () => {
    if (!confirmationCode) {
      setError("Code cannot be empty");
      return; // Stop execution if validation fails
    }

    setLoading(true);
    setError(""); // Clear any previous errors
    try {
      const user = await confirmSignUp({
        username,
        confirmationCode,
      });


      if (user && user.isSignUpComplete) {
        const { isSignedIn } = await autoSignIn();
        if (isSignedIn) {
          const authUser = await getCurrentUser();
          const userData = await fetchAuthSession();

          localStorage.setItem("bookstorecurrentusername", authUser.signInDetails.loginId);
          localStorage.setItem("bookstorecurrentloginuser", authUser.userId);

          // console.log("userData", userData?.tokens?.idToken?.payload)
          const payload = userData?.tokens?.idToken?.payload
          
          const userId = payload.sub;
          const email = payload.email;
          const firstName = payload["custom:firstName"];
          const lastName = payload["custom:lastName"];


          const fullName = `${firstName.trim()} ${lastName}`;
          localStorage.setItem("CurrentFullName", fullName);
          setFullName(fullName);
          

          const AddUserData = {userId:userId,email:email,firstName:firstName,lastName:lastName}

          await handleAddUserAPI(AddUserData)

          setTimeout(() => {
            setLoading(false);
            navigate("/");
            window.location.reload(); // Avoid reloading if not necessary
          }, 500);

          setOtpmodel(false);
        }
      }
    } catch (err) {
      console.error("Error confirming signup:", err);
      if (err.code === 'CodeMismatchException') {
        setError("The OTP code you entered is incorrect. Please try again.");
      } else {
        setError("Failed to confirm OTP. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    try {
      const { destination, deliveryMedium, attributeName } = await resendSignUpCode({ username });
      // console.log("OTP Resent to:", destination);
      setResendCount(prevCount => prevCount + 1);
      setTimer(60); // Reset the timer
    } catch (err) {
      console.error("Error resending OTP:", err);
      setError("Failed to resend OTP. Please try again.");
    }
  };

  return (
    <div className={styles.OTPConfirmationModelMain}>
      <div className={styles.confirmationModel}>
        <div className={styles.otpForm}>
          <h2 className={styles.otpHeading}>Verify Your Email</h2>
          <span className={styles.otpMsg}>OTP Code is Sent to Your Email</span>
          <div className={styles.otpMessageInput}>

            <input
              id="otp"
              name="confirm"
              placeholder="OTP CODE"
              value={confirmationCode}
              className={styles.otpInput}
              onChange={(e) => setConfirmationCode(e.target.value)}
              required
            />
            {error && <div className={styles.errorMsg}>{error}</div>} {/* Display error message */}
          </div>

          {timer === 0 ? (
            <span className={styles.confirmBtnDisableCls}>Confirm</span>
          ) : (
            <button onClick={handleConfirm} className={styles.styledButton}>
              {loading ? <div className={styles.loader}></div> : 'Confirm'}
            </button>
          )}
        </div>
        {timer === 0 && (
          <span className={styles.resendBtn} onClick={handleResendOTP}>
            Resend OTP
          </span>
        )}
        {timer !== 0 && (
          <div className={styles.timer}>
            Time remaining: {timer} seconds
          </div>
        )}
        <span className={styles.closeModel} onClick={() => setOtpmodel(false)}>&times;</span>
      </div>
    </div>
  );
};

export default OTPConfirmationModel;
