import './ZoomLens.css';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'; // Import default styles

const ZoomLens = ({ imageUrl }) => {

return (
    <div className="zoom-container">
      <Zoom
        zoomMargin={50} // Margin around the zoomed image
        zoomOutDelay={300} // Delay for zoom out animation
        zoomInDuration={300} // Duration for zoom in animation
      >
        <img src={imageUrl} alt="Zoomable" className="zoom-image" />
      </Zoom>
    </div>
  );
};

export default ZoomLens;
