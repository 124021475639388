import Homestyles from './Home.module.css'
import mainpageCharacters from '../../Assets/Images/Home/mainpageCharacters.webp'
import '../../fonts.css';
import aboutuscharacter from '../../Assets/Images/Home/AboutusCharacter.webp'
import AboutusComponent from './Components/AboutusComponent/AboutusComponent';
import OurBestSellers from './Components/OurBestSellers/OurBestSellers';
import ReviewsHomeComponent from './Components/ReviewsHomeComponent/ReviewsHomeComponent';
import ContactusHome from './Components/HomeContactus/ContactusHome';
import Footer from '../../Components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa";
import HomeOurBooks from './Components/HomeOurBooks/HomeOurBooks';
import CustomButton from '../../Components/CustomButton/CustomButton';
import { IoIosArrowForward } from 'react-icons/io';

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
   <div className={Homestyles.HomeContainer}>
        <div className={Homestyles.mainpage}>
          <div className={Homestyles.mainpageLeft}>
            <h1 className={Homestyles.heading}>Personalised Children<span className={Homestyles.apostrophe}>'</span>s Story Books</h1>

            <div className={Homestyles.CreateYourOwnBook} >
              <CustomButton incoBg={true} icon={<IoIosArrowForward style={{width:"40px", height:"40px"}} />} outerDivBg={"#BB82F9"} innerDivBg={"#AA76E2"} text={"Create Your Book"} fontSize={"22px"} onClick={()=> navigate('/our-books')} width={"280px"} height={"80px"} topGradient={"rgb(189 158 220)"} bottomGradient={"rgb(156 102 212)"} />
            </div>
          </div>
          <div className={Homestyles.mainpageRight}>
            <img src={mainpageCharacters} alt='mainpageCharacters' loading="lazy"/>
          </div>
        </div>
        <div className={Homestyles.OurBooks}>
          <HomeOurBooks titleColor={"#FFFFFF"}/>
        </div>
        <div className={Homestyles.Aboutus}>
          <AboutusComponent />
          <img src={aboutuscharacter} alt='aboutuscharacter' className={Homestyles.aboutuscharacter} />
        </div>
        <div className={Homestyles.ourbestsellers}>
          <OurBestSellers titleColor={'#FFFFFF'} subheading={'#452565'} />
          <div className={Homestyles.viewallbooks} onClick={() => navigate('/our-books')}>
            See All <FaChevronRight  style={{fontSize:'12px'}}/>
        </div>
        </div>
        <div className={Homestyles.reviews}>
          <ReviewsHomeComponent />
        </div>
        <div className={Homestyles.HomeContactus}>
          <ContactusHome/>
        </div>
        <div className={Homestyles.homeFooter}>
            <Footer />
          </div>
      </div>
    </>
  )
}

export default Home