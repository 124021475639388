import React, { useEffect } from "react";
import styles from "./OrderConfirmPage.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { IoIosArrowForward } from "react-icons/io";
import { useOrder } from "../../context/OrderContext";

const OrderConfirmPage = () => {
  const navigate = useNavigate();
  
  const location = useLocation();
  const { orderDetails, isDigital } = location.state || {}; // Safely access state

  useEffect(() => {
    if (!orderDetails || orderDetails === undefined) {
      navigate("/our-books");
    }
  }, [orderDetails, navigate]);


    const { fetchOrders, orders, setOrders } = useOrder();

  const handleFetchOrders = async () => {
    const id = localStorage.getItem("bookstorecurrentloginuser")
      try {
        await fetchOrders(id);
        // const data = await fetchOrders(customerId);
        // const extractedData = extractData(data);
        // setOrders(extractedData);
  
      } catch (err) {
        console.error("Error fetching customer orders:", err);
        setOrders([]);
      }
    };
  
    useEffect(() => {
      if (orderDetails) {
        handleFetchOrders();
      }
    }, [orderDetails]);

  const handleviewEboooks = () => {
    navigate('/profile', { state: { thenav: 4 } })

  }

  return (
    <>
      {orderDetails || orderDetails !== undefined ? (
        <div className={styles.container}>
          <div className={styles.card}>
            <h1 className={styles.title}>Thank You!</h1>
            <p className={styles.message}>
              Thank you for your order! We can't wait for you to dive into your
              new adventures. Happy reading!
            </p>
            <div className={styles.orderSummary}>
              <h2>Your Order Details</h2>
              <ul>
                <li>
                  <span>Order Number:</span>
                  {orderDetails ? orderDetails.PK : "Null"}
                </li>
                <li>
                  <span>Total Price:</span>{" "}
                  {orderDetails
                    ? `£ ${orderDetails?.paymentDetails?.totalAmount}`
                    : "0"}
                </li>
              </ul>
            </div>
            <div className={styles.buttondiv}>
              <div className={styles.CreateYourOwnBook}>
                <CustomButton
                  incoBg={true}
                  icon={
                    <IoIosArrowForward
                      style={{ width: "30px", height: "30px" }}
                    />
                  }
                  outerDivBg={"#BB82F9"}
                  innerDivBg={"#AA76E2"}
                  text={"Discover More Books"}
                  fontSize={"16px"}
                  onClick={() => navigate("/our-books")}
                  width={"240px"}
                  height={"60px"}
                  topGradient={"rgb(189 158 220)"}
                  bottomGradient={"rgb(156 102 212)"}
                />
              </div>

              {
              isDigital &&
              <dv className={styles.ebooks} onClick={handleviewEboooks}>
                View ebooks
              </dv>
            }
            </div>

            
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default OrderConfirmPage;
