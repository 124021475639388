import ProfileTopStyles from "./cartItems.module.css";
import starIcon from "../../../Assets/SVG/startSvg.svg";
import filledStar from "../../../Assets/SVG/starFilled.svg";
import { removeFromCart, addToCart } from "../../../features/cart/cartSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../fonts.css";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import {
  setcolor,
  setIcon,
  setmessage,
  setsize,
  settoaststate,
  setuniqueId,
  setvariant,
} from "../../../features/toastSlice";
import { useState } from "react";

const stars = [starIcon, starIcon, starIcon, starIcon, starIcon];

export default function CartItems({ cart, setAlert, totalCount }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bulkPurchasepopUp, setBulkPurchasepopUp] = useState(false);

  const today = new Date(); // Gets the current date and time
  const options = { year: "numeric", month: "long", day: "2-digit" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  const ran = Math.random();
  const handleRemoveFromCart = (item) => {
    dispatch(removeFromCart(item));

    dispatch(settoaststate(true));
    dispatch(setsize("sm"));
    dispatch(setvariant("soft"));
    dispatch(setcolor("danger"));
    dispatch(setmessage("Item Removed From Cart"));
    dispatch(setIcon("danger"));
    dispatch(setuniqueId(`${item.bookId}${ran}`));
  };

  const handleAddToCart = (details) => {
    if (totalCount === 10 || totalCount >= 10) {
      setBulkPurchasepopUp(true);
      return;
    }
    dispatch(addToCart(details));

    dispatch(settoaststate(true));
    dispatch(setsize("sm"));
    dispatch(setvariant("soft"));
    dispatch(setcolor("success"));
    dispatch(setmessage("Item Added to you cart"));
    dispatch(setIcon("success"));
    dispatch(setuniqueId(`${details.bookId}${ran}`));
  };

  const priceforbothhardsoft = (item) => {
    const newprice = Number(item)+5.65

    return newprice.toFixed(2)
  }
  return (
    <div className={ProfileTopStyles.profileTopRight}>
      <h1 className={ProfileTopStyles.rightTitle}>Items</h1>
      {cart.length > 0 ? (
        <div className={ProfileTopStyles.profileRightContent}>
          {cart.map((item, index) => (
            <div key={index} className={ProfileTopStyles.orderItem}>
              <div className={ProfileTopStyles.imgContainer}>
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  className={ProfileTopStyles.itemImg}
                />
              </div>
              <div className={ProfileTopStyles.itemDetailContainer}>
                <div className={ProfileTopStyles.itemTop}>
                  <div className={ProfileTopStyles.itemTitleContainer}>
                    <p className={ProfileTopStyles.itemTitle}>{item.title}</p>

                    <div className={ProfileTopStyles.ribbon}>
                      {item?.variation?.["format"] === "both"
                        ? "Hard & Digital"
                        : item?.variation?.["format"] === "soft"
                        ? "Digital only"
                        : "Hard Only"}
                    </div>
                  </div>
                  <div className={ProfileTopStyles.starsContainer}>
                    {Array.from({ length: item.rating }, (_, index) => (
                      <img
                        key={index}
                        src={filledStar}
                        alt="Star"
                        style={{ color: "yellow" }}
                      />
                    ))}
                    {stars.slice(item.rating, 5).map((star, index) => (
                      <img
                        key={index}
                        src={star}
                        alt="Star"
                        style={{ color: "yellow" }}
                      />
                    ))}
                  </div>
                  <div className={ProfileTopStyles.variations}>
                    <div className={ProfileTopStyles.columns}>
                      <ul>
                        <li>
                          <strong>Gender:</strong> {item?.variation?.gender}
                        </li>
                        <li>
                          <strong>Eye Color:</strong>{" "}
                          {item?.variation?.["eye-color"]}
                        </li>
                        <li>
                          <strong>Skin Color:</strong>{" "}
                          {item?.variation?.["skin-color"]}
                        </li>
                        <li>
                          <strong>Hair Style:</strong>{" "}
                          {item?.variation?.["hair-style"]}
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <strong>Hair Color:</strong>{" "}
                          {item?.variation?.["hair-color"]}
                        </li>
                        <li>
                          <strong>Town:</strong> {item?.variation?.town}
                        </li>
                        <li>
                          <strong>Child Name:</strong>{" "}
                          {item?.variation?.["child-name"]}
                        </li>
                        <li>
                          <strong>Glasses:</strong>{" "}
                          {item?.variation?.["glass"] ? "Enabled" : "Disabled"}
                        </li>
                      </ul>
                    </div>
                    {item.bookId === "Teacher" && (
                      <div className={ProfileTopStyles.columns}>
                        <ul>
                          <li>
                            <strong>Class Teacher Name:</strong>{" "}
                            {item?.variation?.teacher1}
                          </li>
                          <li>
                            <strong>P.E Teacher Name:</strong>{" "}
                            {item?.variation?.teacher2}
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <strong>Teaching Asistant Name:</strong>{" "}
                            {item?.variation?.teacher3}
                          </li>
                          <li>
                            <strong>Head Teacher Name:</strong>{" "}
                            {item?.variation?.teacher4}
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={ProfileTopStyles.itemBottom}>
                  <div className={ProfileTopStyles.price_date_container}>
                    <span className={ProfileTopStyles.priceContainer}>
                      Price: £
                      <span className={ProfileTopStyles.price}>
                        {item?.variation?.["format"] === "both"
                          ? 
                          // priceforbothhardsoft(item.price)
                          item.both
                          : item?.variation?.["format"] === "soft"
                          ? item.digital
                          : item.price}
                      </span>
                    </span>

                    <span className={ProfileTopStyles.date}>
                      Ordered: {formattedDate}
                    </span>
                  </div>
                  <div className={ProfileTopStyles.quantity}>
                    <span
                      className={ProfileTopStyles.quantity_btn}
                      onClick={() => handleRemoveFromCart(item)}
                    >
                      -
                    </span>
                    <span className={ProfileTopStyles.quantity_amount}>
                      {item.quantity}
                    </span>
                    <span
                      className={ProfileTopStyles.quantity_btn}
                      onClick={() => handleAddToCart(item)}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={ProfileTopStyles.profileRightContent}>
          <h4 className={ProfileTopStyles.rightSubTitle}>Cart is Empty</h4>
          <ProductionQuantityLimitsIcon
            style={{ fontSize: "30px", color: "gray" }}
          />
          <div className={ProfileTopStyles.leftBtnsContainer}>
            <p className={ProfileTopStyles.rightPara}>
              Go to Our Books and explore
            </p>
            <button
              onClick={() => navigate("/our-books")}
              className={ProfileTopStyles.leftBtns}
              style={{ padding: "5px 0" }}
            >
              Our Books
            </button>
          </div>
        </div>
      )}

      {bulkPurchasepopUp && (
        <div className={ProfileTopStyles.logoutpopup}>
          <div className={ProfileTopStyles.logoutpopupbox}>
            <p className={ProfileTopStyles.textparagraph}>
              For Bulk Purchse Please Contact Us!
            </p>
            <div className={ProfileTopStyles.btns}>
              <span
                onClick={() => setBulkPurchasepopUp(false)}
                className={ProfileTopStyles.cancel}
              >
                Cancel
              </span>
              <span
                onClick={() => navigate("/contactus")}
                className={ProfileTopStyles.logout}
              >
                Contact Us
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
