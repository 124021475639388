import CartTop from "./CartTop/CartTop";
import cartStyles from "./cart.module.css";
import Footer from "../../Components/Footer/Footer";
import React, { useState } from "react";
import CustomAlert from "../../Components/CustomAlert/CustomAlert";

export default function Cart() {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  return (
    <div className={cartStyles.HomeContainer}>
      <div className={cartStyles.OurBooks}>
        <CartTop setAlert={setAlert} />
      </div>
      <div className={cartStyles.Profilefooterbanner}>
        <div className={cartStyles.Aboutus}></div>
      </div>
      <div className={cartStyles.HomeContactus}>
        <div className={cartStyles.Footer}>
          <Footer />
        </div>
      </div>
      {alert.open && (
        <CustomAlert
          alert={alert}
          setAlert={setAlert}
          severity={alert.severity}
          vertical="top"
          horizontal="center"
        />
      )}
    </div>
  );
}
