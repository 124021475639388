import React, { useState } from "react";
import * as XLSX from "xlsx";
import styles from "./VoucherForm.module.css";
import { Oval } from "react-loader-spinner";
import { getUrl } from "aws-amplify/storage";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const VoucherForm = ({ onVoucherAdded, setPopupData, setActiveOption }) => {
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState(""); // Track user selection

  const [fileUrl, setFileUrl] = useState(null);

  const [voucherData, setVoucherData] = useState({
    PK: "",
    voucherName: "",
    Discount: "",
    StartDate: "",
    EndDate: "",
    isActive: true,
    Limit: "",
    campaign: "",
    discountType: "Total",
  });

  // Function to convert Excel serial date to JavaScript Date in YYYY-MM-DD format
  const excelDateToJSDate = (serial) => {
    if (isNaN(serial)) return "";
    const date = new Date((serial - 25569) * 86400 * 1000);
    return date.toISOString().split("T")[0];
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        const formattedData = sheetData.map((row) => ({
          voucherCode: row.voucherCode || "",
          voucherName: row.voucherName || "",
          Discount: row.Discount || "",
          StartDate: row.StartDate ? excelDateToJSDate(row.StartDate) : "",
          EndDate: row.EndDate ? excelDateToJSDate(row.EndDate) : "",
          isActive: row.isActive !== undefined ? row.isActive : true,
          Limit: row.Limit || "",
          campaign: row.campaign || "",
          discountType: row.discountType || "Total",
        }));
        setVouchers(formattedData);
        await handleBulkSubmit(formattedData);
      };
      reader.readAsBinaryString(file);
    } else {
      console.error("No file selected or file is invalid.");
    }
  };

  const handleChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const updatedVouchers = [...vouchers];
    updatedVouchers[index] = {
      ...updatedVouchers[index],
      [name]: type === "checkbox" ? checked : value,
    };
    setVouchers(updatedVouchers);
  };

  const handleBulkSubmit = async (formattedData) => {
    // e.preventDefault();
    setLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/items/admin-add-bulk-vouchers`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formattedData),
        }
      );
      if (res.ok) {
        onVoucherAdded();
        setPopupData({
          message: "Bulk vouchers added successfully!",
          status: "success",
          show: true,
        });
      } else {
        setPopupData({
          message: "Failed to add bulk vouchers!",
          status: "failure",
          show: true,
        });
      }
      setActiveOption("All-Vouchers");
    } catch (error) {
      setPopupData({
        message: "Error adding bulk vouchers!",
        status: "failure",
        show: true,
      });
    }
    setLoading(false);
  };

  const handleChangesingle = (e) => {
    const { name, value } = e.target;
    setVoucherData({ ...voucherData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await fetch(`${apiBaseUrl}/items/admin-add-vouchers`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          PK: voucherData.PK,
          voucherName: voucherData.voucherName,
          campaign: voucherData.campaign,
          Discount: Number(voucherData.Discount),
          StartDate: voucherData.StartDate,
          EndDate: voucherData.EndDate,
          isActive: voucherData.isActive,
          Limit: Number(voucherData.Limit),
          discountType: voucherData.discountType,
        }),
      });
      if (res.ok) {
        onVoucherAdded();
        setPopupData({
          message: "New Voucher Added successfully!",
          status: "success",
          show: true,
        });
        setActiveOption("All-Vouchers");
      } else {
        setPopupData({
          message: "Something went wrong!",
          status: "failure",
          show: true,
        });
      }
    } catch (error) {
      console.error("Error saving voucher:", error);
      setPopupData({
        message: "Something went wrong!",
        status: "failure",
        show: true,
      });
    }
    setLoading(false);
  };

  const downloadLocalFile = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const getUrlResult = await getUrl({
        path: "public/ExcelTemplate/VoucherTemplate.xlsx",
        options: {
          validateObjectExistence: true, // Check if object exists before creating a URL
          expiresIn: 60, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
        },
      });
      const link = document.createElement("a");
      link.href = getUrlResult.url;
      link.download = "VoucherTemplate.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setFileUrl(getUrlResult.url);
    } catch (error) {
      console.error("Error while downloading:", error);
      setPopupData({
        message: "Something went wrong!",
        status: "failure",
        show: true,
      });
    }
    setLoading(false);
  };

  return (
    <div className={styles.voucherFormContainer}>
      {!option && (
        <div className={styles.optionforuploadmain}>
          <h5>Select Option</h5>
          <p className={styles.description}>
            Please choose how you would like to upload your vouchers. You can
            either add a single voucher manually or upload multiple vouchers at
            once using a pre-filled Excel template.
          </p>
          <div className={styles.optionforuploadmainbtns}>
            <button
              onClick={() => setOption("single")}
              className={styles.optionforuploadmainbtn}
            >
              Add Single Voucher
            </button>
            <button
              onClick={() => setOption("bulk")}
              className={styles.optionforuploadmainbtn}
            >
              Bulk Upload
            </button>
          </div>
        </div>
      )}

      {option === "single" && (
        <div className={styles.singlemain}>
          <div className={styles.titleandback}>
            <div className={styles.backbtn} onClick={() => setOption("")}>
              <span>&larr;</span>
              <span>Back</span>
            </div>
          </div>
          <form className={styles.voucherFormContainer} onSubmit={handleSubmit}>
            <h2 className={styles.formTitle}>Add New Voucher</h2>
            <div className={styles.voucherFormBox}>
              <div className={styles.insameline}>
                <div className={styles.cloumnValue}>
                  <span className={styles.inputValue}>Voucher Code</span>
                  <input
                    name="PK"
                    value={voucherData.PK}
                    onChange={handleChangesingle}
                    placeholder="Voucher Code"
                    required
                  />
                </div>
                <div className={styles.cloumnValue}>
                  <span className={styles.inputValue}>Voucher Name</span>
                  <input
                    name="voucherName"
                    value={voucherData.voucherName}
                    onChange={handleChangesingle}
                    placeholder="Voucher Name"
                    required
                  />
                </div>
              </div>
              <div className={styles.insameline}>
                <div className={styles.cloumnValue}>
                  <span className={styles.inputValue}>Discount (%)</span>
                  <input
                    name="Discount"
                    value={voucherData.Discount}
                    onChange={handleChangesingle}
                    placeholder="Discount (%)"
                    type="number"
                    step="0.1"
                    max={100}
                    min={0}
                    required
                  />
                </div>
                <div className={styles.cloumnValue}>
                  <span className={styles.inputValue}>Usage Limit</span>
                  <input
                    name="Limit"
                    value={voucherData.Limit}
                    onChange={handleChangesingle}
                    placeholder="Limit"
                    type="number"
                    min={1}
                    required
                  />
                </div>
              </div>
              <div className={styles.insameline}>
                <div className={styles.cloumnValue}>
                  <span className={styles.inputValue}>Start Date</span>
                  <input
                    name="StartDate"
                    value={voucherData.StartDate}
                    onChange={handleChangesingle}
                    placeholder="Start Date"
                    type="date"
                    required
                  />
                </div>
                <div className={styles.cloumnValue}>
                  <span className={styles.inputValue}>End Date</span>
                  <input
                    name="EndDate"
                    value={voucherData.EndDate}
                    onChange={handleChangesingle}
                    placeholder="End Date"
                    type="date"
                    required
                  />
                </div>
              </div>

              <div className={styles.insamelinesecond}>
                <div className={styles.cloumnValue}>
                  <span className={styles.inputValue}>Discount Type</span>
                  <select
                    style={{ fontSize: "12px", padding: "1rem" }}
                    name="discountType"
                    value={voucherData.discountType} // Set default value as 'Total'
                    onChange={handleChangesingle}
                    className={styles.dropdown} // Add styling here if needed
                    required
                  >
                    <option value="Total">
                      Total (Items Price + Postage and Packaging Cost)
                    </option>
                    <option value="OnlyItems">Items Price Only</option>
                  </select>
                </div>
                <div className={styles.isActivelabel}>
                  <div className={styles.cloumnValue}>
                    <span className={styles.inputValue}>Voucher Status</span>
                    <div className={styles.isActivelabel}>
                      <input
                        name="isActive"
                        type="checkbox"
                        checked={voucherData.isActive}
                        onChange={() =>
                          setVoucherData({
                            ...voucherData,
                            isActive: !voucherData.isActive,
                          })
                        }
                      />
                      <span className={styles.isActivetitle}>
                        {voucherData.isActive ? "Active" : "not-active"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.insameline}>
                <div className={styles.cloumnValue}>
                  <span className={styles.inputValue}>Campaign</span>
                  <input
                    name="campaign"
                    value={voucherData.campaign}
                    onChange={handleChangesingle}
                    placeholder="Campaign"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>

            <button className={styles.submitButton} type="submit">
              Add Voucher
            </button>

            {loading && (
              <div className={styles.loader}>
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#fff"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
          </form>
        </div>
      )}

      {option === "bulk" && (
        <div className={styles.mainbulk}>
          <div className={styles.titleandback}>
            <div className={styles.backbtn} onClick={() => setOption("")}>
              <span>&larr;</span>
              <span>Back</span>
            </div>
          </div>

          <div className={styles.optionforuploadmain}>
            <h2 className={styles.formTitle}>Bulk Upload Vouchers</h2>
            <div className={styles.voucherFormbulk}>
              <div className={styles.insameline}>
                <span className={styles.stepstext}>
                  Step: 1 Download Template File From the link.
                </span>
                <span
                  onClick={downloadLocalFile}
                  className={styles.downloadLink}
                >
                  Download Template File
                </span>
              </div>
              <div className={styles.insameline}>
                <span className={styles.stepstext}>
                  Step: 2 Add data in template and upload file.
                </span>
                <input type="file" accept=".xlsx" onChange={handleFileUpload} />
              </div>
            </div>
          </div>

          {loading && (
            <div className={styles.loader}>
              <Oval
                visible={true}
                height="80"
                width="80"
                color="#fff"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VoucherForm;
